import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetCommentState } from "../../../../component-bundle/comments/commentSlice";
import useCommentsFireBase from "./useCommentsFireBaseHook";


const useCommentFetchHook = (apiParam,reportType) =>  {
  const dispatch = useDispatch();
  const commentsState = useSelector((state) => state.comments);
  const {saveCommentsToFirebase} = useCommentsFireBase();
  const [isCommentSaved, setCommentIsSaved] = useState({
    saved:false,
    commentsIdent:null,
    messages:null,
  });
  


  useEffect(() => {
    if (
      commentsState.type === 'SAVED' &&
      !!commentsState.data &&
      commentsState.data.apiParam === apiParam
    ) {
      if (!!commentsState.data.commentValues) {
        saveCommentsToFirebase(commentsState.data,reportType);
        setCommentIsSaved({
          saved:true,
          commentsIdent:commentsState.data.ident,
          messages:commentsState.messages,
        })
    }
    }
  }, [commentsState, dispatch, reportType]);


  const resetState = () => {
    setCommentIsSaved({
        saved: false,
        commentsIdent:null,
        messages:null,
      },
      () => {
        dispatch(resetCommentState());
      }
    );
  };

  const isCommentSelected = () => {
    return commentsState.selectedCommentNode;
  }

  return {isCommentSaved, isCommentSelected, resetState};
}

export default useCommentFetchHook;
