import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  TextField,
  Tooltip
} from '@mui/material';
import { getLabel } from '../../../utils/helper';
import { setOpenAsset } from './OpenAssetSlice';
import useAssetListHook from '../hooks/useAssetListHook';
import StarIcon from '@mui/icons-material/Star';

// Constants for event names - duplicated in each file that needs them
const DEFAULT_ASSET_CHANGED = 'defaultAssetChanged';

const AssetSwitcher = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const [selectedAsset, setselectedAsset] = useState({});
  const [assetsDropdown, setAssetDropdown] = useState([]);
  const openAsset = useSelector((state) => state.openAsset);
  const dispatch = useDispatch();
  const {assetList, fetchList} = useAssetListHook();
  const [isDefaultAsset, setIsDefaultAsset] = useState(false);

  useEffect(() => {
    if(assetList.isFetchSuccess && assetList.assets.length > 0) {
      try {
        // Get default asset from localStorage
        const storedDefault = localStorage.getItem('defaultAsset');
        let defaultAssetIdent = null;
        if (storedDefault) {
          const defaultAsset = JSON.parse(storedDefault);
          if (defaultAsset.projectIdent === scope.projectIdent) {
            defaultAssetIdent = defaultAsset.ident;
          }
        }

        const list = assetList.assets.map((a) => ({
          index: a.index,
          name: a.name,
          ident: a.assetIdent,
          isDefault: a.assetIdent === defaultAssetIdent,
          subAssetList: a?.subAssetListDto?.map((s) => ({
            value: s.name,
            id: s.subAssetIdent,
          })),
          users: a?.userDtos?.map((u) => ({
            label: `${u.firstName} ${u.lastName}`,
            value: u.emailAddress,
          })),
          projectIdent: scope.projectIdent,
        }));
        
        setAssetDropdown(list);
      } catch (error) {
        console.error('Error loading assets:', error);
      }
    } else if(!assetList.isFetchSuccess) {
      fetchList();
    }
  }, [assetList, scope.projectIdent, fetchList]);

  // Listen for default asset changes
  useEffect(() => {
    const handleDefaultAssetChange = (event) => {
      if (event.detail?.ident) {
        const assetIdent = event.detail.ident;
        setAssetDropdown(prev => prev.map(asset => ({
          ...asset,
          isDefault: asset.ident === assetIdent
        })));
        
        if (selectedAsset?.ident === assetIdent) {
          setIsDefaultAsset(true);
        }
      } else if (event.detail?.removed) {
        setAssetDropdown(prev => prev.map(asset => ({
          ...asset,
          isDefault: false
        })));
        setIsDefaultAsset(false);
      }
    };

    window.addEventListener(DEFAULT_ASSET_CHANGED, handleDefaultAssetChange);
    return () => {
      window.removeEventListener(DEFAULT_ASSET_CHANGED, handleDefaultAssetChange);
    };
  }, [selectedAsset]);

  // Update selected asset when openAsset changes
  useEffect(() => {
    if(assetsDropdown.length > 0 && openAsset?.ident) {
      const found = assetsDropdown.find((f) => f.ident === openAsset.ident);
      if (found) {
        setselectedAsset(found);
        setIsDefaultAsset(found.isDefault);
      }
    }
  }, [assetsDropdown, openAsset]);

  const getSelectedAsset = (val) => {
    if (props.dirtyState) {
      props.setBlockState(val);
    } else {
      setselectedAsset(val);
      setIsDefaultAsset(val.isDefault);
      dispatch(setOpenAsset({
        ...val,
        subAssetIdent: null, // Clear sub-asset when switching assets
        subAssetName: null
      }));
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Autocomplete
        id="assetsOptions"
        size="small"
        autoHighlight={true}
        style={{ padding: 5, flexGrow: 1 }}
        value={selectedAsset || ''}
        options={assetsDropdown}
        onChange={(event, newValue) => {
          if (newValue?.ident) {
            getSelectedAsset(newValue);
          }
        }}
        getOptionLabel={(option) => option.name || ''}
        renderOption={(props, option) => (
          <li {...props}>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <span style={{ flexGrow: 1 }}>{option.name}</span>
              {option.isDefault && (
                <Tooltip title="Default Asset">
                  <StarIcon fontSize="small" style={{ color: '#FFD700' }} />
                </Tooltip>
              )}
            </div>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            minRows={4}
            variant="outlined"
            size="small"
            label={getLabel('_select asset')}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {selectedAsset?.ident && isDefaultAsset && (
                    <Tooltip title="Default Asset">
                      <StarIcon fontSize="small" style={{ color: '#FFD700' }} />
                    </Tooltip>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

AssetSwitcher.propTypes = {
  dirtyState: PropTypes.bool,
  setBlockState: PropTypes.func,
  filterAssetList: PropTypes.array,
};

export default AssetSwitcher;