import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { 
  Grid, 
  TextField, 
  Box, 
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText 
} from "@mui/material";
import { Field } from "formik";
import { getLabel, getOverlineText } from "../../../../utils/helper";
import PlacesAutocomplete from "../../../../components/locations/PlacesAutocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getLanguageList } from "../../../../common/dropdowns";
import { fetchClientData } from "../../../../api/projectListApi";

const languageList = getLanguageList();

function CreateProjectForm(props) {
  const { values, setFieldValue, touched, errors, readOnly } = props;
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [clientList, setClientList] = useState([]);
  const state = useSelector((state) => state.projectList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchClientData());
  }, [dispatch]);

  useEffect(() => {
    if (
      state.clientList.type === "FETCHED" &&
      state.clientList.data.length > 0
    ) {
      setClientList(state.clientList.data);
    }
  }, [state.clientList]);

  return (
    <>
      <Grid container style={{ marginTop: 10 }} justify="center" spacing={2}>
        <Grid item md={6} xs={12}>
          <Grid item md={12} xs={12}>
            {getOverlineText("Project Information")}
          </Grid>
          <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
            <Field
              name="projectName"
              disabled={readOnly}
              label={getLabel("Project Name")}
              as={TextField}
              size="small"
              dense={"true"}
              className="grid-fields"
              error={touched.projectName && errors.projectName}
              helperText={touched.projectName && errors.projectName}
            />
          </Grid>
          <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
            <Field
              disabled={readOnly}
              as={TextField}
              name="projectNumber"
              label={getLabel("_project-number")}
              className="grid-fields"
              size="small"
              dense={"true"}
              error={touched.projectNumber && errors.projectNumber}
              helperText={touched.projectNumber && errors.projectNumber}
            />
          </Grid>
          <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
            <Field
              inputProps={{
                maxLength: 255,
              }}
              disabled={readOnly}
              as={TextField}
              name="projectDescription"
              label={getLabel("Project Description")}
              className="grid-fields"
              size="small"
              rows={3}
              multiline
              maxRows={10}
              dense="true"
              error={touched.projectDescription && errors.projectDescription}
              helperText={
                touched.projectDescription && errors.projectDescription
              }
            />
          </Grid>
          <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
            <Box className="d-flex grid-fields">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={readOnly}
                  value={
                    values.startDate !== null
                      ? dayjs(new Date(values.startDate))
                      : dayjs(new Date())
                  }
                  style={{ margin: 5, width: "100%" }}
                  label={getLabel("_start-date")}
                  autoOk
                  format="DD/MM/YYYY"
                  onChange={(date) => {
                    if (!!date) {
                      setFieldValue("startDate", date);
                    } else {
                      setFieldValue("startDate", null);
                    }
                  }}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      size: "small",
                      helperText: null,
                      error: null,
                    },
                    inputAdornment: {
                      position: "start",
                    },
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={readOnly}
                  label={getLabel("_end-date")}
                  value={
                    values.endDate !== null
                      ? dayjs(new Date(values.endDate))
                      : dayjs(new Date())
                  }
                  style={{ margin: 5, width: "100%" }}
                  autoOk
                  format="DD/MM/YYYY"
                  onChange={(date) => {
                    if (!!date) {
                      setFieldValue("endDate", date);
                    } else {
                      setFieldValue("endDate", null);
                    }
                  }}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      size: "small",
                      helperText: null,
                      error: null,
                    },
                    inputAdornment: {
                      position: "start",
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid md={12} xs={12} style={{ marginTop: "10px" }}>
            <Autocomplete
              disabled={readOnly}
              id="project-language"
              value={values.language}
              onChange={(event, newValue) => {
                values.language = newValue;
              }}
              options={languageList?.map((option) => option.label)}
              getOptionSelected={(option, value) =>
                value.value === option.value
              }
              renderInput={(params) => (
                <Field
                  as={TextField}
                  {...params}
                  label={getLabel("_language")}
                  name="language"
                  className="grid-fields"
                  size="small"
                  dense="true"
                  disabled={readOnly}
                  error={touched.language && errors.language}
                  helperText={touched.language && errors.language}
                />
              )}
            />
          </Grid>
          <Grid md={12} xs={12} style={{ marginTop: "10px" }}>
            <Field
              disabled={true}
              name="serviceLine"
              label={getLabel("Service Line")}
              as={TextField}
              size="small"
              dense={"true"}
              className="grid-fields"
              error={touched.serviceLine && errors.serviceLine}
              helperText={touched.serviceLine && errors.serviceLine}
            />
          </Grid>
          <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
            <Field
              name="projectThreshold"
              disabled={readOnly}
              label={getLabel("Project Threshold")}
              as={TextField}
              size="small"
              dense={"true"}
              className="grid-fields"
              error={touched.projectThreshold && errors.projectThreshold}
              helperText={touched.projectThreshold && errors.projectThreshold}
            />
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid item md={12} xs={12}>
            {getOverlineText("Client Information")}
          </Grid>
          <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
            <Autocomplete
              disabled={readOnly}
              id="free-solo-client-name"
              freeSolo
              value={values.clientName || ""}
              options={clientList}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.clientName;
              }}
              onInputChange={(event, newInputValue) => {
                if (typeof newInputValue === "string") {
                  setFieldValue("clientName", newInputValue);
                }
              }}
              onChange={(event, newValue) => {
                if(!!newValue){
                  setFieldValue("clientName", newValue?.clientName);
                  setFieldValue("clientIdent", newValue?.clientIdent);
                }else {
                  setFieldValue("clientName", "");
                  setFieldValue("clientIdent", null);
                }
                
              }}
              renderInput={(params) => (
                <Field
                  as={TextField}
                  {...params}
                  label={getLabel("Client Name")}
                  name="clientName"
                  className="grid-fields"
                  size="small"
                  dense="true"
                  multiline
                  disabled={readOnly}
                  error={touched.clientName && errors.clientName}
                  helperText={touched.clientName && errors.clientName}
                />
              )}
            />
          </Grid>
          <Grid item md={12} xs={12}></Grid>
          <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
            {getOverlineText("Client Address Detail")}
          </Grid>
          <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
            {!props.readOnly && (
              <div className="grid-fields">
                <PlacesAutocomplete
                  setLat={setLat}
                  setLng={setLng}
                  setGetAddress={(address) => {
                    setFieldValue("clientAddress", address.street);
                    setFieldValue("clientCity", address.city);
                    setFieldValue("clientZip", address.postalCode);
                    setFieldValue("clientState", address.state);
                    setFieldValue("clientCountry", address.country);
                  }}
                  fullAddress={fullAddress}
                />
              </div>
            )}
          </Grid>
          <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
            <Field
              disabled={readOnly}
              name="clientAddress"
              label={getLabel("_Address")}
              as={TextField}
              size="small"
              dense={"true"}
              multiline
              maxRows={5}
              className="grid-fields"
              error={touched.clientAddress && errors.clientAddress}
              helperText={touched.clientAddress && errors.clientAddress}
            />
          </Grid>
          <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
            <Field
              disabled={readOnly}
              name="clientCity"
              label={getLabel("_City")}
              as={TextField}
              size="small"
              dense={"true"}
              className="grid-fields"
              error={touched.clientCity && errors.clientCity}
              helperText={touched.clientCity && errors.clientCity}
            />
          </Grid>
          <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
            <Field
              disabled={readOnly}
              name="clientZip"
              label={getLabel("_zip-code")}
              as={TextField}
              size="small"
              dense={"true"}
              className="grid-fields"
              error={touched.clientZip && errors.clientZip}
              helperText={touched.clientZip && errors.clientZip}
            />
          </Grid>
          <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
            <Field
              disabled={readOnly}
              name="clientState"
              label={getLabel("_State")}
              as={TextField}
              size="small"
              dense={"true"}
              className="grid-fields"
              error={touched.clientState && errors.clientState}
              helperText={touched.clientState && errors.clientState}
            />
          </Grid>
          <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
            <Field
              disabled={readOnly}
              name="clientCountry"
              label={getLabel("_Country")}
              as={TextField}
              size="small"
              dense={"true"}
              className="grid-fields"
              error={touched.clientCountry && errors.clientCountry}
              helperText={touched.clientCountry && errors.clientCountry}
            />
          </Grid>
          <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
            {getOverlineText("Type Of Report")}
          </Grid>
          <Grid item xs={12} md={6} ml={2.5}>
            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={touched.extendedRedflag && Boolean(errors.extendedRedflag)}
              disabled={readOnly}
            >
              <InputLabel id="report-type-label">{getLabel("Type Of Report")}</InputLabel>
              <Select
                labelId="report-type-label"
                id="extendedRedflag"
                name="extendedRedflag"
                value={values.extendedRedflag}
                onChange={(e) => {
                  setFieldValue("extendedRedflag", e.target.value);
                }}
                label="Report Type"
              >
                <MenuItem value={false}>Red Flag & Full Report</MenuItem>
                <MenuItem value={true}>Extended Red Flag</MenuItem>
              </Select>
              {touched.extendedRedflag && errors.extendedRedflag && (
                <FormHelperText error>{errors.extendedRedflag}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}></Grid>
        </Grid>
      </Grid>
    </>
  );
}

CreateProjectForm.defaultProps = {
  readOnly: false,
};

CreateProjectForm.propTypes = {
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
};

export default CreateProjectForm;
