import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../asset-overview/assetOverviewSlice";
import { fetchAssetList } from "../../../api/assetOverviewApi";

// Constants for event names - duplicated in each file that needs them
const DEFAULT_ASSET_CHANGED = 'defaultAssetChanged';
const DEFAULT_SUBASSET_CHANGED = 'defaultSubAssetChanged';

function useAssetListHook() {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const [assetList, setAssetList] = useState({
    isFetchSuccess: false,
    assets: [],
  });
  const assets = useSelector((state) => state.assetOverview);

  // Enhanced to include default asset/sub-asset checks
  useEffect(() => {
    if (assets.assetList.type === "SUCCESS" || assets.assetList.type === "FETCHED") {
      try {
        // Get stored defaults
        const storedDefaultAsset = localStorage.getItem('defaultAsset');
        const storedDefaultSubAsset = localStorage.getItem('defaultSubAsset');
        const defaultAsset = storedDefaultAsset ? JSON.parse(storedDefaultAsset) : null;
        const defaultSubAsset = storedDefaultSubAsset ? JSON.parse(storedDefaultSubAsset) : null;

        // Filter and enhance asset list with default flags
        const enhancedAssets = assets.assetList.data
          .filter((e) => !!e)
          .map(asset => ({
            ...asset,
            isDefault: defaultAsset?.ident === asset.assetIdent && 
                      defaultAsset?.projectIdent === scope.projectIdent,
            subAssetListDto: asset.subAssetListDto.map(subAsset => ({
              ...subAsset,
              isDefault: defaultSubAsset?.subAssetIdent === subAsset.subAssetIdent && 
                        defaultSubAsset?.assetIdent === asset.assetIdent &&
                        defaultSubAsset?.projectIdent === scope.projectIdent
            }))
          }));

        setAssetList({
          isFetchSuccess: true,
          assets: enhancedAssets,
        });
      } catch (error) {
        console.error('Error processing asset list:', error);
        // Fallback to basic asset list without defaults
        setAssetList({
          isFetchSuccess: true,
          assets: assets.assetList.data.filter((e) => !!e),
        });
      }
      dispatch(resetState());
    }
  }, [assets, dispatch, scope.projectIdent]);

  const fetchList = useCallback(() => {
    if (scope.projectIdent) {
      const request = {
        api: scope.api,
        projectIdent: scope.projectIdent,
      };
      dispatch(fetchAssetList(request));
    }
  }, [scope.api, scope.projectIdent, dispatch]);

  // Helper to refresh defaults from localStorage
  const refreshDefaults = useCallback(() => {
    if (!assetList.isFetchSuccess) return;
    
    try {
      const storedDefaultAsset = localStorage.getItem('defaultAsset');
      const storedDefaultSubAsset = localStorage.getItem('defaultSubAsset');
      const defaultAsset = storedDefaultAsset ? JSON.parse(storedDefaultAsset) : null;
      const defaultSubAsset = storedDefaultSubAsset ? JSON.parse(storedDefaultSubAsset) : null;

      // Only consider defaults that match the current project
      const validDefaultAsset = 
        defaultAsset && defaultAsset.projectIdent === scope.projectIdent ? defaultAsset : null;
      
      const validDefaultSubAsset = 
        defaultSubAsset && defaultSubAsset.projectIdent === scope.projectIdent ? defaultSubAsset : null;

      const updatedAssets = assetList.assets.map(asset => ({
        ...asset,
        isDefault: validDefaultAsset?.ident === asset.assetIdent,
        subAssetListDto: asset.subAssetListDto.map(subAsset => ({
          ...subAsset,
          isDefault: validDefaultSubAsset?.subAssetIdent === subAsset.subAssetIdent && 
                    validDefaultSubAsset?.assetIdent === asset.assetIdent
        }))
      }));

      setAssetList(prev => ({
        ...prev,
        assets: updatedAssets
      }));
    } catch (error) {
      console.error('Error refreshing defaults:', error);
    }
  }, [assetList.assets, assetList.isFetchSuccess, scope.projectIdent]);

  // Listen for both default asset and default sub-asset changes
  useEffect(() => {
    const handleDefaultChange = (event) => {
      // If this is a forced update, re-fetch asset list entirely
      if (event.detail?.force) {
        fetchList();
      } else {
        refreshDefaults();
      }
    };

    window.addEventListener(DEFAULT_ASSET_CHANGED, handleDefaultChange);
    window.addEventListener(DEFAULT_SUBASSET_CHANGED, handleDefaultChange);

    return () => {
      window.removeEventListener(DEFAULT_ASSET_CHANGED, handleDefaultChange);
      window.removeEventListener(DEFAULT_SUBASSET_CHANGED, handleDefaultChange);
    };
  }, [refreshDefaults, fetchList]);

  return { assetList, fetchList, refreshDefaults };
}

export default useAssetListHook;