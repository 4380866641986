import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  language:"",
  leaving: false,
  extendedRedflag: false,
};

const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setSystemLanguage: (state, { payload }) => ({
      ...state,
      language: payload,
    }),
    sidebar: (state, { payload }) => ({
      ...state,
      leaving: payload,
    }),
    setExtendedRedflag: (state, { payload }) => ({
      ...state,
      extendedRedflag: payload,
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  setSystemLanguage,
  sidebar,
  setExtendedRedflag,
} = systemSlice.actions;

// A selector
export const systemSliceSelector = (state) => state.system;

// The reducer
export default systemSlice.reducer;
