import React, { useMemo, useEffect, useState } from "react";
import DocumentMap from "./DocumentMap";
import {
  AppBar,
  Box,
  Card,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Slide,
  Toolbar,
  Tooltip,
} from "@mui/material";
import ContentContainer from "./ContentContainer";
import { useReportStructureContextHook } from "./ReportStructureContext";
import AssetSwitcher from "../../../component-bundle/assetSwitcher/AssetSwitcher";
import NoAssets from "../../../component-bundle/NoAssets";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { fullReport, redFlag } from "../../../../utils/defaultValues";
import { useSelector, useDispatch } from "react-redux";
import {
  getDialogBoxHeader,
  getIntlTranslation,
} from "../../../../utils/helper";
import ReportGenerationDialog from "../../../component-bundle/generate-report/ReportGenerationDialog";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";
import BreadcrumbProjectContainer from "../../../../layout/app-container/BreadcrumbProjectContainer";
import { setOpenAsset } from "../../../component-bundle/assetSwitcher/OpenAssetSlice";

// Constants for event names
const DEFAULT_ASSET_CHANGED = 'defaultAssetChanged';
const DEFAULT_SUBASSET_CHANGED = 'defaultSubAssetChanged';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReportContainer = () => {
  const { reportType, fullScreenMode, setFullScreenMode } =
    useReportStructureContextHook();

  const getReportOptions = () =>
    reportType?.toUpperCase() === "REDFLAG" ? redFlag : fullReport;

  return (
    <>
      {!fullScreenMode ? (
        <RenderReportContainer />
      ) : (
        <Dialog
          open={fullScreenMode}
          TransitionComponent={Transition}
          fullScreen
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setFullScreenMode(false);
            }
          }}
          disableBackdropClick
          disableEscapeKeyDown
          style={{ overflow: "hidden" }}
        >
          <AppBar
            style={{
              position: "sticky",
              height: 40,
            }}
            className="header w-100 rounded-0"
          >
            <Toolbar className={"dialog-toolbar"}>
              <div className="d-flex align-items-center justify-content-between w-25">
                {getDialogBoxHeader(getReportOptions().heading)}
              </div>
              <div className="d-flex align-items-center justify-content-between w-75">
                <BreadcrumbProjectContainer
                  showAsset={true}
                  showSubAsset={false}
                />
                <IconButton
                  edge="start"
                  color="inherit"
                  id="create_project_close"
                  onClick={() => setFullScreenMode(false)}
                  aria-label="close"
                  style={{ marginLeft: "auto" }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          <DialogContent
            dividers
            className={"w-100 p-2"}
            style={{ height: "calc(100% - 50px)", overflow: "hidden" }}
          >
            <RenderReportContainer />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

function RenderReportContainer() {
  const dispatch = useDispatch();
  const scope = useSelector((state) => state.container.scope);
  const {
    assets,
    selectedItem,
    reportType,
    openAsset,
    getUpdatedRows,
    openReviewMode,
    fullScreenMode,
    setFullScreenMode,
  } = useReportStructureContextHook();

  const [blockChange, setBlockChange] = useState({
    block: false,
    newAsset: null,
    action: null,
  });

  // Add this useEffect hook to initialize default assets
  useEffect(() => {
    // Check if we need to initialize with a default asset
    const initializeDefaults = async () => {
      if (!openAsset?.ident) {
        try {
          const storedDefaultAsset = localStorage.getItem('defaultAsset');
          if (storedDefaultAsset) {
            const defaultAsset = JSON.parse(storedDefaultAsset);
            
            // Only use default if it belongs to current project
            if (defaultAsset.projectIdent === scope.projectIdent) {
              // Check for default sub-asset too
              const storedDefaultSubAsset = localStorage.getItem('defaultSubAsset');
              let defaultSubAssetData = null;
              
              if (storedDefaultSubAsset) {
                const parsedSubAsset = JSON.parse(storedDefaultSubAsset);
                if (parsedSubAsset.assetIdent === defaultAsset.ident && 
                    parsedSubAsset.projectIdent === scope.projectIdent) {
                  defaultSubAssetData = parsedSubAsset;
                }
              }
              
              // Prepare the asset data
              const assetData = {
                assetName: defaultAsset.name,
                ident: defaultAsset.ident,
                isDefault: true,
                projectIdent: scope.projectIdent
              };
              
              // Add sub-asset data if available
              if (defaultSubAssetData) {
                assetData.subAssetName = defaultSubAssetData.name;
                assetData.subAssetIdent = defaultSubAssetData.subAssetIdent;
                assetData.isSubAssetDefault = true;
              }
              
              // Update Redux state with default selection
              dispatch(setOpenAsset(assetData));
            }
          }
        } catch (error) {
          console.error('Error initializing with default asset:', error);
        }
      }
    };
    
    initializeDefaults();
  }, [dispatch, scope.projectIdent, openAsset?.ident]);

  // Also add event listener for emergency default changes
  useEffect(() => {
    const handleEmergencyDefaultChange = (event) => {
      if (event.detail?.emergency) {
        console.log('Report container received emergency default change signal');
        
        try {
          // Force a complete refresh based on current localStorage state
          const storedDefaultAsset = localStorage.getItem('defaultAsset');
          if (storedDefaultAsset) {
            const defaultAsset = JSON.parse(storedDefaultAsset);
            
            if (defaultAsset.projectIdent === scope.projectIdent) {
              // Check for default sub-asset too
              const storedDefaultSubAsset = localStorage.getItem('defaultSubAsset');
              let defaultSubAssetData = null;
              
              if (storedDefaultSubAsset) {
                const parsedSubAsset = JSON.parse(storedDefaultSubAsset);
                if (parsedSubAsset.assetIdent === defaultAsset.ident && 
                    parsedSubAsset.projectIdent === scope.projectIdent) {
                  defaultSubAssetData = parsedSubAsset;
                }
              }
              
              // Prepare the asset data
              const assetData = {
                assetName: defaultAsset.name,
                ident: defaultAsset.ident,
                isDefault: true,
                projectIdent: scope.projectIdent
              };
              
              // Add sub-asset data if available
              if (defaultSubAssetData) {
                assetData.subAssetName = defaultSubAssetData.name;
                assetData.subAssetIdent = defaultSubAssetData.subAssetIdent;
                assetData.isSubAssetDefault = true;
              }
              
              // Update Redux state with default selection
              dispatch(setOpenAsset(assetData));
            }
          } else {
            // No default set, clear selection
            dispatch(setOpenAsset({
              assetName: null,
              ident: null,
              subAssetName: null,
              subAssetIdent: null,
              isDefault: false,
              isSubAssetDefault: false
            }));
          }
        } catch (error) {
          console.error('Error handling emergency default change in report container:', error);
        }
      }
    };
    
    window.addEventListener(DEFAULT_ASSET_CHANGED, handleEmergencyDefaultChange);
    
    return () => {
      window.removeEventListener(DEFAULT_ASSET_CHANGED, handleEmergencyDefaultChange);
    };
  }, [dispatch, scope.projectIdent]);

  const getReportOptions = () =>
    reportType?.toUpperCase() === "REDFLAG" ? redFlag : fullReport;

  const getDataList = useMemo(
    () =>
      assets?.map((a) => ({
        name: a.name,
        ident: a.assetIdent,
        projectIdent: scope.projectIdent,
        isParent: true,
        parentIdent: a.assetIdent,
        users: a?.userDtos?.map((u) => ({
          label: `${u.firstName} ${u.lastName}`,
          value: u.emailAddress,
        })),
      })),
    [assets, scope.projectIdent]
  );

  const getSelectedData = useMemo(() => {
    let found = assets?.find((a) => a.assetIdent === openAsset.ident);
    if (found) {
      return {
        name: found.name,
        ident: found.assetIdent,
        isParent: true,
        parentIdent: found.assetIdent,
        users: found?.userDtos?.map((u) => ({
          label: `${u.firstName} ${u.lastName}`,
          value: u.emailAddress,
        })),
        projectIdent: scope.projectIdent,
      };
    }
    return null;
  }, [assets, openAsset.ident, scope.projectIdent]);

  const render = () => {
    return (
      <Box className="w-100 h-100 d-flex">
        {assets.length > 0 ? (
          <>
            <Box className="w-100 h-100 d-flex flex-column">
              <Box
                className="w-100 d-flex flex-row align-items-center justify-content-between m-1 rounded-1"
                style={{ backgroundColor: "#c1d0f0" }}
              >
                <Box style={{ width: "70%" }}>
                  <AssetSwitcher
                    dirtyState={
                      getUpdatedRows()?.data?.filter((f) => f.updated)?.length >
                      0
                    }
                    setBlockState={(val) =>
                      setBlockChange({
                        block: true,
                        newAsset: val,
                        action: "asset-switch",
                      })
                    }
                  />
                </Box>
                <Box
                  className="d-flex p-1 rounded-3 report-comments align-items-center justify-content-between"
                  style={{
                    height: 40,
                    width: "30%",
                    backgroundColor: "#d08f8e",
                  }}
                >
                  {openAsset.ident && (
                    <div
                      style={{ width: "40%" }}
                      className="d-flex flex-grow-1 h-100"
                    >
                      <ReportGenerationDialog
                        reportOptions={[getReportOptions()]}
                        selectedData={getSelectedData}
                        filterDataList={getDataList}
                        id={`report-generation`}
                        className="text-light p-1"
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "30%",
                      flexGrow: "1",
                      height: "100%",
                    }}
                  >
                    <Divider
                      orientation="vertical"
                      style={{
                        width: 4,
                        backgroundColor: "#ffffff",
                        height: "100%",
                        margin: "0px 5px",
                      }}
                      flexItem
                    />
                    <Tooltip title="Review Mode" className="h-100 p-1">
                      <Box
                        className="g-btn-elevated d-flex align-items-center h-100 p-1 w-100 justify-content-between"
                        style={{ color: "#ffffff", cursor: "pointer" }}
                        onClick={(e) => openReviewMode()}
                      >
                        <p
                          style={{
                            fontSize: 12,
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                          className="m-0"
                        >
                          Review
                        </p>
                        <RateReviewIcon
                          style={{
                            color: "#ffffff",
                            fontSize: 20,
                            marginLeft: 5,
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </div>
                  {!fullScreenMode && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "10%",
                        flexGrow: "1",
                        height: "100%",
                      }}
                    >
                      <Divider
                        orientation="vertical"
                        style={{
                          width: 4,
                          backgroundColor: "#ffffff",
                          height: "100%",
                          margin: "0px 5px",
                        }}
                        flexItem
                      />
                      <Tooltip title="Fullscreen" className="h-100">
                        <IconButton
                          size="small"
                          aria-label="Full screen"
                          style={{ width: 22, height: 22, color: "#ffffff" }}
                          onClick={() => setFullScreenMode(true)}
                          id={"full-screen"}
                        >
                          <FullscreenIcon
                            style={{
                              color: "#ffffff",
                              fontSize: 22,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </Box>
              </Box>
              <Box
                className="w-100 d-flex flex-row"
                style={{ height: "calc(100% - 50px)" }}
              >
                <Card className="m-1 d-flex" style={{ width: "25%" }}>
                  <DocumentMap />
                </Card>
                <Card className="m-1 d-flex" style={{ width: "75%" }}>
                  {!!selectedItem.ident && <ContentContainer />}
                </Card>
              </Box>
            </Box>
          </>
        ) : (
          <NoAssets />
        )}
      </Box>
    );
  };

  return <>{render()}</>;
}

export default ReportContainer;