/*

Table of Contents
1. menu items
*/

module.exports = {
  /* 1.Menu Items */
  "menu.home": "Startseite",
  "menu.user-management": "Benutzer-verwaltung",
  "menu.data-collection": "Daten-erhebung",
  "menu.create-project": "Projekt erstellen",
  "menu.tdd-management": "TDD",
  "menu.pm-management": "Projekt monitoring",
  "menu.esg-management": "ESG",
  "menu.tam-management": "TAM",
  "menu.project-list": "Projekte",
  "menu.user-dashboard": "Benutzer übersicht",
  "menu.user-profile": "Benutzerprofil",
  "menu.project-dashboard": "Projekt übersicht",
  "menu.project-description": "Projekt beschreibung",
  "menu.project-information": "Projekt informationen",
  "menu.asset-overview": "Gebäude übersicht",
  "menu.building-profile": "Gebäudeprofil",
  "menu.defects": "Risiken",
  "menu.photo-documentation": "Foto dokumentation",
  "menu.capexOverview": "Capex Übersicht",
  "menu.redFlag": "Red Flag",
  "menu.fullReport": "Full Report",
  "menu.statutoryCompliance": "Statutory Compliance",
  "menu.customReport": "Individualisierter Bericht",
  "menu.infra-management": "Infrastruktur",
  "menu.general-assessment": "Allgemeine Einschätzung",
  "menu.key-findings": "Key Findings",
  "menu.project-progress": "Projektfortschritt",
  "menu.project-variations": "Variationen",
  "menu.project-documentation": "Dokumentation",
  "menu.project-conclusion": "Fazit",
  "menu.task-management": "Aufgabenverwaltung",
  "menu.cost-dataRoom": "Kosten-Datenraum",
  "tag.fact-sheet": "Datenblatt",
  "tag.summary": "Zusammenfassung",
  "tag.progress": "Fortschritt",
  "tag.others": "Sonstige",
  "tag.overview": "Übersicht",
  "menu.review": "Überprüfung",
  "menu.client-management": "Kunden-management",
  "menu.data-analysis": "Daten Analyse",
  "menu.preview-document": "Vorschau Dokument",
  "menu.chat": "Chatten",

  /* 2. header Items */

  "header.handbookDownload":
    "Klicken Sie hier zum Herunterladen der Systemanleitung",

  /* 3. Authentication Items */
  "user.login": "Anmeldung",
  "user.password": "Passwort",
  "user.confirmPassword": "Passwort bestätigen",
  "user.emailAddress": "E-Mail Adresse",
  "user.emailRequired": "E-Mail ist erforderlich",
  "user.passwordRequired": "Passwort ist erforderlich",
  "user.forgotPassword": "Passwort vergessen?",
  "user.username": "Benutzernamen",
  "user.invalidEmail": "Ungültige E-Mail-Adresse",
  "user.usernameRequired": "Benutzername ist erforderlich",
  "user.firstnameRequired": "Vorname ist erforderlich",
  "user.lastnameRequired": "Nachname ist erforderlich",
  "user.languageRequired": "Benutzersprache ist erforderlich",
  "user.resetPassword": "Passwort zurücksetzen",
  "user.firstName": "Vorname",
  "user.lastName": "Nachname",
  "user.contact": "Kontakt",
  "user.contactNumber": "Telefonnummer",
  "user.work": "Arbeit",
  "user.title": "Titel",
  "user.office": "Bürostandort",
  "user.systemLanguage": "Systemsprache",
  "user.language": "Sprache",
  "user.account": "Konto",
  "user.signOut": "Abmelden",
  "user.role": "Benutzerrolle",

  /* 4. Guru Messages*/
  "guru.welcome": "Willkommen bei TA Guru",
  "guru.messages": "Ein Produkt von TA Europe Analytics GmbH",

  /* 5. common */
  "project-overview": "Projektübersicht",
  required_field: "Pflichtfeld",
  _projects: "Projekte",
  "_no records": "Keine Datensätze verfügbar",
  _search: "Suche",
  _generalInformation: "Allgemeine Informationen",
  "Project Name": "Projektname",
  "_project-number": "Projektnummer",
  "Project Description": "Projektbeschreibung",
  "Project Threshold": "Wesentlichkeitsschwelle",
  "team_members_info": "Nur Projektleiter und Projektassistenten können neue Teammitglieder hinzufügen.",
  _language: "Sprache",
  "Type Of Report": "Berichtstyp",
  "Client Information": "Kundeninformation",
  "Client Address Detail": "Kundenadresse",
  "Client Name": "Kundenname",
  _Address: "Adresse",
  _City: "Stadt",
  "_zip-code": "Postleitzahl",
  _State: "Bundesland",
  _Country: "Land",
  "Add Users TO Project": "Benutzer zum Projekt hinzufügen",
  _status: "Status",
  "_comments-Ta": "Kommentar TA Europe",
  _start: "Starten",
  _finish: "Beenden",
  "Team Members": "Team-Mitglieder",
  "Project Information": "Projektinformationen",
  add_team_member: "Möchten Sie Teammitglieder zu diesem Projekt hinzufügen?",
  _trigger_team_member_add_email:
    "Möchten Sie E-Mails an Teammitglieder senden?",
  "create user": "Benutzer erstellen",
  _Asset: "Asset",
  "Address Detail": "Adressdaten",
  "_building type": "Gebäudetyp",
  "_select asset": "Asset auswählen",
  report_version: "Berichtsversion",
  Asset: "Asset",
  User: "Benutzer",
  "_Asset Information": "Asset-Informationen",
  "_Sub-Asset Information": "Sub-Assets Informationen",
  _Name: "Name",
  "_Building Count": "Anzahl der Gebäude",
  "_SubAsset Count": "Anzahl der Sub-Assets",
  "_Address Details": "Adresse Details",
  _building: "Gebäude",
  _Type: "Typ",
  "_Email Address": "E-Mail Adresse",
  _email: "E-Mail",
  _position: "Position",
  _Permission: "Zugriffsrechte",
  _Author: "Autor",
  _quelle: "Quelle",
  "_select all": "Alle auswählen",
  _Subasset: "Sub-Asset",
  _general: "Allgemein",
  "_marked-general": "(Markiert als allgemein)",
  "_same-as-asset": "Von Asset übernehmen",
  _description: "Beschreibung",
  "_Regional Factor": "Regionalfaktor",
  "_Renovation Year": "Renovierungsjahr (wenn zutreffend)",
  "_create-report-version": "Version erstellen",
  categories: "Kategorien",
  _item_name: "Eigenschaft Name",
  _item_type: "Eigenschaft Typ",
  "_my-projects": "Meine Projekte",
  "_project-library": "Projektbibliothek",
  "_type-user-search": "Typ für die Benutzersuche",
  "_building-profile": "Gebäudeprofil",
  _defects: "Risiken",
  "_photo-documentation": "Fotodokumentation",
  "_start-date": "Startdatum",
  "_end-date": "Enddatum",
  "_trade-information": "Trade Information",
  _trade: "Trade",

  /* 6. messages*/
  _saved: "Informationen wurden erfolgreich gespeichert",
  "search google maps": "Suche auf Google Maps",
  "short project summary":
    "Erstellen Sie eine kurze Zusammenfassung des Projekts",
  "comparison mode option":
    "Wollen Sie diese Version mit der vorangehenden Version vergleichen?",
  "current version": "Aktuelle Version",
  version: "Version",
  _compare: "Vergleich von",
  _properties: "Eigenschaft",
  "Optional Properties": "Optionale Eigenschaft",
  "Add Custom Properties": "Benutzerdefinierte Eigenschaften erstellen",
  add_custom_properties_placeholder:
    "Klicken Sie auf " + ", um eine neue Eigenschaft hinzuzufügen",
  "_leave warning": "Sind Sie sicher die Seite verlassen zu wollen?",
  "_unsaved changes": "Sie haben folgende ungespeicherte Änderungen:",
  "_no-changes": "Keine Änderungen gefunden, Version ist aktuell",
  "_action prompt":
    "Klicken Sie auf 'Zurück' zurückzukehren, oder auf 'Weiter', ungespeicherte Änderungen zu ignorieren und fortzufahren.",
  "List of Sub-Assets": "Liste der Sub-Assets",
  "_Create Another Asset": "Ein weiteres Asset erstellen",
  "_Mark as complete": "Als vollständig markieren",
  "_Construction Year": "Baujahr",
  "mark-report-verison-current":
    "Möchten Sie diese Version als eine aktuelle Version markieren?",
  no_data_click_start:
    "Keine Einträge gefunden, klicken Sie hier, um einen neuen Eintrag zu erstellen",
  "_existing property": "Vorhandene Eigenschaften",
  "_existing categories": "Vorhandene Kategorien",
  "_no existing property":
    "Keine vorhandenen Eigenschaften gefunden. Bitte erstellen Sie einen neuen Eintrag.",
  "_property not found":
    "Die Eigenschaft wurde in dieser Version des Berichts nicht gefunden.",
  _unsaved_changes: "Sie haben ungespeicherte Änderungen an:",
  "_requested-service": "Leider ist diese Funktion nicht verfügbar.",
  "_report-selection": "Bericht Auswahl",
  "_Capex-alert":
    "Diese Objekt enthält defekt, für die die CAPEX-ID nicht existiert. Könnten Sie den CAPEX bitte neu erstellen?",
  "_report-type": "Berichtstyp",
  "_report-language": "Berichtssprache",
  "_report-version-type": "Typ der Berichtsversion",
  "_appendix-required": "Anlage Erforderlich",
  "_include-appendix": "Anlage Einschließen",
  _Generate: "Erzeugen",
  "_generate-report": "Bericht generieren",
  "_Cost-Defect": "Kostenmangel",
  _Defect: "Defekt",
  _cancel: "Abbrechen",
  "_Choose-Element-Type": "Elementtyp wählen",
  "_Element-Name": "Element Name",
  "_element-type": "Elementtyp",
  "_Element-Construction-Year": "Element Baujahr",
  "_Cost-Data": "Kosten-Daten",
  "_cost-estimates": "Kostenkennwert",
  _from: "Minimum",
  _middle: "Mittle",
  _upto: "Maximum",
  _cost: "Kosten",
  "_Q&A": "Q&A",
  "_created-by": "Erstellt von",
  _Comments: "Kommentare",
  "_Data-Room-Index": "Datenraum Index",
  _Copy: "Kopieren",
  "_Delete-Image": "Bild löschen",
  "_Save-Image": "Bild speichern",
  "_Non-Cost-Defect": "Nicht kostenpflichtiger Mangel",
  "_Choose-Risk-Type": "Wählen Sie einen Risikotyp",
  "_risk-Type": "Risikotyp",
  _risk: "Risiko",
  "_Defect-Description": "Mangelbeschreibung",
  "_defect-and-measure": "Defekt & Maßnahme",
  "_Measure-Description": "Beschreibung der Maßnahme",
  "_Choose-a-Defect-Type": "Auswahl des Mangeltyps",
  "_Defect-Type": "Mangeltyp",
  "_Choose-a-Priority-Type": "Wählen Sie einen Prioritätstyp",
  "_Priority-Type": "Prioritätstyp",
  _Priority: "Priorität",
  "_Short-Term": "Kurzfristig",
  "_Medium-Term": "Mittelfristig",
  "_Long-Term": "Langfristig",
  Multiple: "Mehrfach",
  "no cost proposal": "Keine Kostenvoranschläge vorhanden",
  Comments: "Kommentare",
  "_Extra-Cost-Comments": "Kommentar (intern)",
  "_Extra-Cost-(Optional)": "Zusatzkosten (optional)",
  "_Extra-Cost": "Zusatzkosten",
  "_Cost-estimate-for-CAPEX": "Kostenschätzung für Capex",
  _Total: " Gesamt",
  "% probability of occurrence": " % Eintrittswahrscheinlichkeit",
  "_%-of-the-element-affected": " % Betroffene Elemente",
  "Project Factor": "Projektfaktor",
  _Quantity: "Menge",
  _Unit: "Einheit",
  "_Price-per-Unit": " Preis pro Einheit",
  "_min-value-1-and-max-value-100": " Min. Wert 1 max. Wert 100",
  "Building Count": " Anzahl der Gebäude",
  "SubAsset Count": " Anzahl der Sub-Assets",
  _threshold:
    "Der Schwellenwert für Kostenmängel beträgt 10.000 €. Sie können den Mangel trotzdem anlegen aber bitte sprechen Sie mit dem Projektleiter über diesen Mangel.",
  "_cost-suggestion-available": "Kostenvorschläge vorhanden",
  "_Create-Another-Defect": "Einen weiteren Defekt erstellen",
  "_Our-Recommendation-For": "Unsere Empfehlung für",
  _AddToRedFlag: "Als wesentliches Risiko kennzeichnen",
  "_AddToRedFlag-Warning-1": "Sie haben für diesen Fehler ein",
  "_AddToRedFlag-Warning-2":
    "Risiko ausgewählt. Es wird empfohlen, solche Mängel nicht als wesentliches risiko zu kennzeichnen.",
  _Low: "Niedrig",

  "_Select-sub-Asset-to-copy": "Zu kopierendes Sub-Asset auswählen",
  "_List-of-Sub-Assets": "Liste der Sub-Assets",
  "_Drag-Images":
    "Ziehen Sie die Dateien per Drag & Drop hierher oder klicken Sie, um Dateien auszuwählen.",
  "_Image-Allowed":
    "(Es werden nur *.jpeg- und *.png-Bilder akzeptiert, bitte keine Hochformatbilder hochladen)",
  "_Capex-Needs-To-Be-Generated": "Capex muss erstellt werden",
  _Restore: "Wiederherstellen",
  "_drop-the-files": "Bitte die Datei hier einfügen",
  "_No-Defect-Images": "Keine Mangelfotos verfügbar",
  "_Save-All": "Alle speichern",
  "_General-Photos": "Allgemeine Fotos",
  "_Defect-Photos": "Mangelfotos",
  "_general-sub-assets":
    "Möchten Sie diesen Sub-Asset als allgemein kennzeichnen?",
  "_general-sub-assets-info":
    "Sie können einen allgemeinen Sub-Asset verwenden um gemeinsame Daten für alle Sub-Assets hinzuzufügen oder die Daten für alle Sub-Assets zusammenzufassen.",
  "_individual-info-sub-assets":
    "Möchten Sie Informationen in einzelnen Sub-Assets hinzufügen?",
  "_no-capex-data":
    "Bitte überprüfen ob für dieses Projekt Assets erstellt wurden oder  der Capex Data bereits erstellt wurde.",
  "_Save-All-Images": "Alle Bilder speichern",
  "_capex-overview": "CAPEX-Übersicht",
  "_Add-new-label": "Ein neues Label hinzufügen",
  "_missing-label":
    "Haben Sie ein Label in unserer Liste vermisst? Bitte fügen Sie es hinzu!",
  "_add-comment": "Einen Kommentar hinzufügen...",
  "_no-data": "Für die gewählte Option sind leider keine Daten verfügbar.",
  "_English-Translation": "Englische Übersetzung",
  "_German-Translation": "Deutsche Übersetzung",
  "_optional-deault-text": "Standardtext (Optional)",
  "_Add contact": "Zu Kontakten hinzufügen",
  "_Remove contact": "Aus Kontakt entfernen",
  "_Data is missing": "Keine Daten, bitte aktualisieren",
  _Mobile: "Mobil",
  "_Office Name": "Bürostandort",
  "_max contact allowed": "Maximal 4 Kontakte sind erlaubt.",
  _history: "Historie",
  "_max files allowed":
    "Es können maximal so viele Dateien hochgeladen werden wie !",
  "_No Rows": "Keine den Filterkriterien entsprechenden Zeilen wurden gefunden",
  "_no-report":
    "Bitte überprüfen Sie, ob für dieses Projekt Assets erstellt wurden.",
  "_add-headers": "Kopfzeilen hinzufügen für ",
  _headers: "Kopfzeilen",
  "_Header Name": "Kopfzeile Name",
  "_add-header button": "Eine benutzerdefinierte Kopfzeile hinzufügen",
  "_header added":
    "Neue Kopfzeile wurde zur Liste der Kopfzeilen unten hinzugefügt:",
  "_header exists":
    "Der Angegebene Kopfzeilenname existiert bereits, bitte beben Sie einen nicht existierenden Kofpzeilenname an",
  "_Drag and drop header":
    "Ziehen Sie die Kopfzeile herunter, um Ihre Kopfzeilentabelle neu anzuordnen",
  "_Please not empty":
    "Stellen Sie bitte sicher, dass der Handelsname nicht leer ist. Handelsname ist erforderlich.",
  _Rearrange: "Kopfzeile neu anordnen oder eine neue Kopfzeile hinzufügen",
  _Internal: "Intern",
  _Client: "Kunde",
  "_report language": "Wählen Sie eine Berichtssprache",
  "_Report Selection": "Berichtsauswahl ",
  "_report type": "Wählen Sie einen Bericht",
  "_report-version": "Berichtsversion",
  "_Select Headers":
    "Bitte wählen Sie die Kopfzeilen, die im Bericht angezeigt werden sollen",
  "_Save or select": "Vorhandene Kopfzeilen speichern oder auswählen",
  "_Select Sub-Asset": "Bitte wählen Sie ein Sub-Asset um die Daten anzuzeigen",
  "_add-header": "Kopfzeile hinzufügen",
  "Last Change": "Letzte Änderung",
  "_Asset-User-Management": "Asset-Benutzerverwaltung",
  "_Inflation rate": "Vorschlag für die Inflationsrate",
  "_percent symbol": "Geben Sie die Zahl ohne das Prozentsymbol ein",
  _Expand: "Ausklappen",
  _Collapse: "Zusammenklappen",
  "_view-all": "Alle anzeigen",
  "_Search Costs": "Suche Kosten",
  "_Search Task": "Suche Aufgabe",
  "_filter-category": "Nach Kategorie filtern",
  "_Inflation Rate": "Inflationsrate für alle Kosten",
  "_Download Cost element": " Kostenelement in Excel herunterladen",
  "_Upload cost elment": " Excel-Kostenaufstellung importieren ",
  "_Add Child": "Kosten hinzufügen",
  "_Add Sibling": "Kosten kopieren",
  "_Single Inflation Rate": "Eine einzige Inflationsrate aktualisieren",
  "_sorry-request":
    "Es tut uns leid,dass wir Ihre Anfrage nicht erfüllen können.",
  no_asset_found_click_start:
    "Wir konnten keine assets / sub-assets in diesem Projekt oder Ihnen zugeordnet finden. Bitte klicken Sie hier, um mit der Erstellung von Vermögenswerten zu beginnen oder fordern Sie Schreibzugriff vom Projektassistenten an.",
  "_mark as current": "Als aktuell markieren",
  "_edit version name": "Versionsname bearbeiten",
  "_mark as temp version": "Als temporäre Version markieren",
  "_delete report version": "Bericht Version entfernen",
  "_delete report version-1": "Sind Sie sicher, dass Sie die Berichtsversion",
  "_delete report version-2": "löschen wollen? Diese Aktion kann nicht rückgängig gemacht werden.",
  "_cancel": "ABBRECHEN",
  "delete": "LÖSCHEN",
  "_confrim-deletion": "Bestätigen Sie die Löschung",
  "_undo temp version": "Temporäre Version rückgängig machen",
  "_temp alert":
    "Sie arbeiten derzeit an einer temporären Version unter Asset: {asset_name} Version: {version_name}",
  "_select sub-asset-to-start":
    "Bitte wählen Sie das Sub-Asset aus, um zu beginnen",
  _technical_issues:
    "Leider haben wir einige technische Probleme mit dem Versand von E-Mails. Bitte wenden Sie sich an guru@taeurope.com um eine schnelle Lösung zu finden.",
  _readOnly_mode:
    "Das Projekt ist nur im Lesemodus mit eingeschränktem Zugriff geöffnet.",
  "_show all": "Alle Kostenvorschläge",
  _unsave_copy_action_changes: "Nicht gespeicherte kopierte Daten",
  "_unsaved-data": "Nicht gespeicherte Daten",
  "_delete-alert-text":
    "Sind Sie sicher, dass Sie das Folgende löschen wollen?",
  "_question-mark": "?",
  _warning: "Warnung",
  "_cost-suggestion": "Kostenvorschläge",
  "_cost-suggestion-not-available":
    "Leider haben wir keine Kostenvorschläge für Ihre Auswahl.Wir bitten Sie um Ihre wertvolle Mithilfe bei der Meldung der vermissten Daten, indem Sie uns eine E-Mail an guru@taeurope.com senden.",
  "_cost-suggestion-select-defect-measure":
    "Bitte wählen Sie Defekt und Maßnahme, um den Kostenvorschlag zu sehen.",
  _mark_row_delete:
    "Die Zeile wurde als gelöscht markiert. Bitte klicken Sie auf 'Speichern', um Ihre Änderungen zu speichern.",
  "_label-required": "Etikett darf nicht leer sein",
  "_all-comments": "Kommentare",
  "_show-all-comments": "Alle Kommentare anzeigen",
  "_forgot-password": "Passwort vergessen?",
  "_enter-your-email":
    "Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link, mit dem Sie sich wieder in Ihr Konto einloggen können.",
  "_passwords-match": "Passwörter müssen übereinstimmen",
  "_valid-password-format":
    "Das Passwort muss mindestens 6 Zeichen lang sein und aus einer Kombination von Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen bestehen.",
  _reset_inavlid_taken:
    "Der Link zum Zurücksetzen des Passworts scheint abgelaufen zu sein. Sie können einen neuen Link erhalten unter",
  _replies: "antwortet",
  _reply: "Antwort",
  _edited: "(bearbeitet)",
  "_add-comments": "Kommentare hinzufügen",
  _timeline: "Zeitleiste",
  "_changes-from": "Änderungen von",
  "_current-text": "Aktueller Text",
  "_client-list": "Kundenliste",
  _or: "oder",
  "_custom-operation": "benutzerdefinierte Aktion",

  "_short-term": "kurzfristig (Jahr 1)",
  "_medium-term": "mittelfristig (Jahr 2 - 5)",
  "_long-term": "langfristig (Jahr 6 - 10)",
  "_bau-construction": "Baukonstr.",
  _tga: "TGA",
  "_outdoor-facilities": "Außenanlagen",
  "_fire-life-safety": "Brandschutz",
  _confirmity: "Konformität",
  _lifecycle: "Lebenszyklus",
  _defect: "Defekt",
  _prevention: "Prävention",
  "_confirmity-defect": "Konformität",
  _projectRedirect: "Offenes Projekt",
  "_area-applicable":
    "Gebäudeprofil Flächen (Bitte nur die verfügbaren Flächeninformationen eintragen und die restlichen Felder leer lassen)",
  "_parking-applicable":
    "Gebäudeprofil Parkplätze (Bitte geben Sie nur die verfügbaren Parkplätze an und lassen Sie die restlichen Felder frei)",
  "_floor-applicable":
    "Gebäudeprofil Stockwerke (Bitte geben Sie nur die verfügbaren Stockwerksinformationen ein und lassen Sie die übrigen Felder leer)",
  "_existing-structure": "Bestehende Struktur",
  "_no-data-available": "Keine Daten verfügbar",
  _review: "überprüfen",
  "_reopen-thread": "erneut öffnen",
  "_delete-thread": "Thema löschen",
  "_edit-comment": "Kommentar bearbeiten",
  "_resolve-threads": "Aufgelöste Themen",
  "_resolve-thread": "Thema auflösen",
  "_open-threads": "Offene Themen",
  "_no-comments-found": "Keine Kommentare gefunden",
  "_no-comments-create":
    "Keine Kommentare gefunden, Sie können einen erstellen, indem Sie auf das Symbol klicken.",
  _markComplete: "Markieren Sie die Überprüfung als abgeschlossen",
  "_type-comments": "Geben Sie hier Ihren Kommentar ein...",
  _post: "Post",
  "_no-contacts":
    "Es wurden keine Kontakte ausgewählt, bitte wählen Sie Benutzer für Kontaktinformationen aus.",
  "_total-images-uploaded": "Hochgeladene Bilder",
  "_comments-assigned": "Kommentare Zugewiesen (offen)",
  "_numeric-format": "Eingabe wird automatisch formatiert zu",
  "_unknown-value": "Nutze '0' bei Unbekanntem.",
  "_open-images": "Bilder öffnen",
  _selected: "ausgewählt",
  "_text-editor-placeholder": "Beginnen Sie hier mit der Texteingabe...",
  "_checkbox-copy-data":
    "Bitte aktivieren Sie das Kontrollkästchen, um die Daten zur aktuellen Version hinzuzufügen.",
  /* 7. buttonText  */

  "_download-template": "Vorlage herunterladen",
  _create: "Erstellen",
  _save: "Speichern",
  add: "Hinzufügen",
  register: "Registrieren",
  "_Create Asset": "Asset erstellen",
  "_create another": "Ein weiteres Asset erstellen",
  "_Create Sub-Asset": "Sub-Asset erstellen",
  _edit: "Bearbeiten",
  "_Edit User": "Benutzer bearbeiten",
  _delete: "Löschen",
  "_Begin Full Report": "Full Report beginnen",
  "_Asset Template": "Asset-Vorlage",
  "_Defect Template": "Defect-Vorlage",
  "_Upload Asset File": "Asset Datei hochladen",
  "_Upload Defect File": "Defect Datei hochladen",
  "_add-properties": "Eigenschaft hinzufügen",
  "_add-row": "Zeile hinzufügen",
  _copy: "Kopieren",
  _back: "Zurück",
  _continue: "Weiter",
  "_create-category": "Kategorie erstellen",
  "_redirect-to-asset-overview": "Weiterleitung zur Asset-Übersicht",
  "_add-more": "weiter hinzufügen",
  "_show-more": "Mehr Anzeigen",
  _close: "Schließen",
  _submit: "Einreichen",
  "_no-changes-found": "Keine Änderungen gefunden",
  "_add-filters": "Filter hinzufügen",
  _reset: "zurücksetzen",
  "_remove-all": "Alle entfernen",
  "_apply-filters": "Filter anwenden",
  "_sort-by-asc": "Sort by ASC",
  "_sort-by-desc": "Sort by DESC",
  "_capex-summary": "Capex Summary",
  _certificate: "ZERTIFIKAT",
  _isChecked: "Verfügbar",
  _isNotChecked: "Nicht verfügbar",
  _feedback: "Feedback Team Guru geben",
  "_user-editable": "Vom Benutzer bearbeitbar",
  _select: "Wählen Sie",
  _translation: "Übersetzung",
  "_send-email": "E-Mail senden",

  /* 8. tooltip */
  "tooltip.edit-asset": "Klicken Sie zum bearbeiten des Objekt",
  "tooltip.delete-asset": "Klicken Sie zum löschen des Assets",
  "tooltip.create-report-verion":
    "Klicken Sie zum erstellen und starten einer neuen Berichtversion",
  "tooltip.create-asset": "Klicken Sie zum erstellen von Assets",
  "tooltip.add-row": "Klicken Sie zum hinzugüfen einer Zeile",
  "tooltip.edit": "Klicken Sie zum bearbeiten",
  "tooltip.save": "Klicken Sie zum speichern der Änderungen",
  "tooltip.delete": "Klicken Sie zum löschen",
  "tooltip.Create Sub-Asset": "Klicken Sie zum erstellen von Sub-Assets",
  "tooltip.Delete sub-Asset": "Klicken Sie zum löschen von Sub-Assets",
  "tooltip.open_reports_dialog":
    "Klicken Sie zum anzeigen der Berichtsoptionen",
  "tooltip.Begin-Full-Report": "Klicken Sie zum starten des Full Reports",
  "tooltip.Download-Asset-Template":
    "Klicken Sie zum herunterladen der Vorlage für die Asset-Datei",
  "tooltip.Download-Defect-Template":
    "Klicken Sie zum herunterladen der Vorlage für die Defect-Datei",
  "tooltip.Upload-Asset-File": "Klicken Sie zum hochladen einer Asset-Datei",
  "tooltip.Upload-Defect-File": "Klicken Sie zum hochladen einer Defect-Datei",
  "tooltip.cancel": "Klciken Sie  zum abbrechen",
  "tooltip.copy": "Klicken Sie zum kopieren der Werte",
  "tooltip.Save-Building-Information": "Gebäudeinformationen speichern",
  "tooltip.click-to-generate": "Klicken Sie zum erzeugen",
  "tooltip.close-defect-dialog": "Defektdialog schließen",
  "tooltip.Save-Defect": "Defekt speichern",
  "tooltip.create-Defect": "Mangel erstellen",
  "tooltip.Click-to-close": "Klicken Sie zum schließen",
  "tooltip.Copy-Defects": "Mängel kopieren",
  "tooltip.Save-All": "Alles speichern",
  "tooltip.Click-to-paste-selected-rows":
    "Klicken Sie zum Einfügen ausgewählter Zeilen",
  "tooltip.Save-Image": "Bild speichern",
  "tooltip.Delete-Image": "Bild löschen",
  "tooltip.add-custom":
    "Klicken Sie zum Hinzuzufügen benutzerdefinierter Werte ",
  "tooltip.Save-All-Images": "Klicken Sie zum Speichern aller Bilder",
  "tooltip.Save": "Klicken Sie zum Speichern",
  "tooltip.Save-report-Text": "Klicken Sie zum Speichern des Berichtstextes",
  "tooltip.save-the-screenshot":
    "Klicken Sie zum Speichern des Screenshot des Standorts",
  "tooltip.leaving-warn":
    "Klicken Sie hier wenn Sie sicher sind, dass Sie diese Seite verlassen möchten.",
  "tooltip.create-project": "Klicken Sie zum Erstellen eines Projekts",
  "tooltip.redirect-to-asset-overview":
    "Klicken Sie um zur Asset-Übersicht zurückzukehren",
  "tooltip.reorder": "Klicken Sie zum Neuordnen von Eigenschaften",
  "tooltip.asset-user-management":
    "Klicken Sie zum Verwalten von Asset-Benutzern",
  "tooltip.remove": "Klicken Sie auf , um den neuen Eintrag zu entfernen",
  "tooltip.show-more-details":
    "Klicken Sie hier, um die Kosten im Detail zu sehen",
  "dialog.delete-Asset": "Asset löschen",
  "dialog.Update-Asset": "Asset aktualisieren",
  "tooltip.add-Image": "Zum Hinzufügen von Bildern klicken",
  "tooltip.add-comments": "Klicken Sie zum Hinzufügen von Kommentaren",
  "tooltip.show-all": "klicken, um alle zu sehen",
  "tooltip.reply": "Zum Antworten klicken",
  "tooltip.fullscreen": "Klicken Sie auf , um den Vollansicht-Modus zu öffnen",
  "tooltip.menu": "menu",
  "tooltip.switch-to-client-list": "Zur Client-Listenansicht wechseln",
  "tooltip.switch-to-project-list": "Zur Projektlistenansicht wechseln",
  "tooltip.remove-all": "Klicken Sie auf Alle entfernen.",
  "tooltip.apply-all": "Anklicken, um Filter anzuwenden",
  "tooltip.open": "Anklicken zum Öffnen",
  "tooltip.help-us-improve": "Helfen Sie, Guru zu verbessern",
  "tooltip.add-structure": "zum Hinzufügen der Struktur anklicken",
  "tooltip.add-header": "Klicken Sie, um die Kopfzeile hinzuzufügen",
  "tooltip.preview-mode": "Klicken Sie, um den Vorschaumodus zu öffnen",
  "tooltip.send-email": "Klicken, um eine E-Mail zu senden",
  "tooltip.review-mode": "Klicken, um den Überprüfungsmodus zu öffnen",
  "tooltip.comments-assigned": "Kommentare, die Ihnen zugewiesen sind",
  "tooltip.reload": "Klicken Sie zum Aktualisieren der Daten",

  /* Chat with PDF + Preview Document */
  "chat-with-pdf.title": "Chatten mit PDF",
  "chat-with-pdf.question": "Eine Frage stellen",
  "chat-with-pdf.send": "Senden",
  "chat-with-pdf.greetings": "Wie kann ich Ihnen heute helfen?",
  "chat-with-pdf.warning-not-searchable-by-text":
    "Diese PDF-Datei ist nicht nach Text durchsuchbar und kann daher nicht analysiert werden. Bitte versuchen Sie es mit einer durchsuchbaren PDF-Datei. Wir befinden uns derzeit in der Testphase.",

  "chat-with-pdf.warning-large-token":
    "Da wir uns noch in der Testphase befinden, hat dieses PDF eine große Tokengröße und kann nicht analysiert werden. Bitte versuchen Sie es mit einer kleineren PDF-Datei.",

  "preview-document.select": "Bitte wählen Sie eine Datei zur Vorschau",
  /* Define Risks Types */
  "low-warning":
    "Mängel/Probleme, die keine sofortige Maßnahmen oder eine Klärung erfordern, sowie Lebenszyklus-Probleme, die im zukünftigen Wartungsprogramm berücksichtigt werden sollten, da das Ende der voraussichtlichen Lebensdauer im Betrachtungszeitraum erreicht wird.",
  "medium-warning":
    "Mängel/Probleme, die ein sofortiges Handeln oder eine Klärung erfordern, da die Funktionalität/Nutzbarkeit der Immobilie gefährdet ist, sowie überfällige Lebenszyklusprobleme (erheblicher Instandhaltungsrückstau), da das Ende der erwarteten Lebensdauer überschritten ist. Risiken eines solchen finanziellen Umfangs, dass sie sich auf den Wert der Immobilie auswirken und kurz- bis mittelfristig nach Abschluss der Transaktion behoben werden sollten.",
  "high-warning":
    "Mängel, die sofortige Aufmerksamkeit und Klärung erfordern da entweder die Gesundheit und/oder Sicherheit, einschließlich der baulichen Sicherheit, des Brandschutzes und der Einhaltung von Baurecht/Vorschriften, gefährdet sind. Daher sollten solche Fragen vor oder als Teil der Transaktion geklärt/gelöst werden.",

  // High risk translations
  "high-warning-start":
    "Mängel, die sofortige Aufmerksamkeit und Klärung erfordern da entweder die",
  health: "Gesundheit",
  "and-or": "und/oder",
  safety: "Sicherheit",
  including: "einschließlich der",
  "structural-safety": "baulichen Sicherheit",
  "fire-protection": "des Brandschutzes",
  "building-laws": "Einhaltung von Baurecht",
  "high-warning-end":
    "Vorschriften, gefährdet sind. Daher sollten solche Fragen vor oder als Teil der Transaktion geklärt/gelöst werden.",

  // Medium risk translations
  "medium-warning-start":
    "Mängel/Probleme, die ein sofortiges Handeln oder eine Klärung erfordern, da die",
  "functionality-usability": "Funktionalität/Nutzbarkeit",
  is: "der Immobilie",
  "at-risk": "gefährdet ist",
  "as-well-as": "sowie",
  "overdue-lifecycle": "überfällige Lebenszyklusprobleme",
  "medium-warning-end":
    "(erheblicher Instandhaltungsrückstau), da das Ende der erwarteten Lebensdauer überschritten ist. Risiken eines solchen finanziellen Umfangs, dass sie sich auf den Wert der Immobilie auswirken und kurz- bis mittelfristig nach Abschluss der Transaktion behoben werden sollten.",

  // Low risk translations
  "defects-problems": "Mängel/Probleme",
  "low-warning-middle":
    "die keine sofortige Maßnahmen oder eine Klärung erfordern, sowie Lebenszyklus-Probleme, die im",
  "future-maintenance": "zukünftigen Wartungsprogramm",
  "low-warning-end":
    "berücksichtigt werden sollten, da das Ende der voraussichtlichen Lebensdauer im Betrachtungszeitraum erreicht wird.",

  // Days
  Monday: "Montag",
  Tuesday: "Dienstag",
  Wednesday: "Mittwoch",
  Thursday: "Donnerstag",
  Friday: "Freitag",
  Saturday: "Samstag",
  Sunday: "Sonntag",
};
