import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { Box, Button, Card, CardActions, CardContent, Divider, Grow } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import AddIcon from "@mui/icons-material/Add";
import { setOptionalHeaders } from '../statutoryComplianceSlice';
import ReorderAndHeaderDialog from './ReorderAndHeaderDialog';
import { getIntlTranslation } from '../../../../../utils/helper';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import AddBox from '@mui/icons-material/AddBox';
import { callbackify } from 'util';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  position: 'relative',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: 'hidden',
  zIndex: 1030,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  position: 'relative',
  overflow: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(5)} + 1px)`,
  },
  zIndex: 1030,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  backgroundColor: '#7facd5',
  height: 80,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function ToolPanel(props) {
  const dispatch = useDispatch();
  const [headers, setHeaders] = useState([]);
  const inspection = useSelector((state) => state.tddInspection);
  const [open, setOpen] = useState(true);
  const [openCustomHeader, setOpenCustomHeader] = useState(false);
  const [currentCategorie, setCurrentCategorie] = useState({});
  const [settingsOpen, setSettingsOpen] = useState({
    column: false,
    groupBy: false,
  });
  const handleDrawerOpen = () => {
    setOpen(!open);
    let tempObj = {};
    Object.keys(settingsOpen).forEach((key) => {
      tempObj[key] = false;
    });

    setSettingsOpen(tempObj);
  };

  useEffect(() => {
    if (props.columnList.length) {
     let temp = props.columnList.map((m) => {
      let obj = {...m}
      let found = m.categories.find((o) => o.category.ident === inspection.tab.ident);
      return {...obj,order:found?.headerOrder}
     })
     temp.sort((a, b) => a.order-b.order);
      setHeaders(temp);
    }
  }, [inspection.tab.ident, props.columnList]);
  useEffect(() => {
    if (props.currentCategorie) {
      setCurrentCategorie(props.currentCategorie);
    }
  }, [props.currentCategorie]);
 
useEffect(() => {
  if (headers.length > 0) {
    const disabledColumns = headers.filter(
      col => col.key === 'qAndNumber' || col.key === 'overdue'
    );
    
    if (disabledColumns.length > 0) {
      let currentOptionalHeaders = [...inspection.optionalHeaders];
      let needsUpdate = false;
      
  
      disabledColumns.forEach(col => {
        const foundIndex = currentOptionalHeaders.findIndex(
          h => h.key === col.key && h.categories.ident === inspection.tab.ident
        );
        
        if (foundIndex === -1) {
          currentOptionalHeaders.push({
            categories: inspection.tab,
            checked: true,
            key: col.key,
            name: col.name
          });
          needsUpdate = true;
        } else if (!currentOptionalHeaders[foundIndex].checked) {
          currentOptionalHeaders[foundIndex].checked = true;
          needsUpdate = true;
        }
      });
      
      
      if (needsUpdate) {
        dispatch(setOptionalHeaders(currentOptionalHeaders));
      }
    }
  }
}, [headers, inspection.tab.ident]);

  const selectAll = (e) => {
    let optionalHeaders = [...inspection.optionalHeaders];
    let temp = headers.map((val) => {
      if (optionalHeaders.length > 0) {
        let found = optionalHeaders.findIndex(
          (m) =>
            m.key === val.key && m.categories.ident === inspection.tab.ident
        );
        if (found >=0 ) {
          optionalHeaders.splice(found, 1);
        }
        return {
          categories: inspection.tab,
          checked: e.target.checked,
          key: val.key,
          name: val.name,
        };
      } else {
        return {
          categories: inspection.tab,
          checked: e.target.checked,
          key: val.key,
          name: val.name,
        };
      }
    });
    dispatch(setOptionalHeaders([...optionalHeaders, ...temp]));
  };

  const numberOfChecked = () => {
    return inspection.optionalHeaders.filter(
      (f) => f.checked && f.categories.ident === inspection.tab.ident
    ).length;
  };

  const onChecked = (checked, val) => {
    let temp = prepareCheckedOptions(checked, val);
    dispatch(setOptionalHeaders(temp));
  };

  const prepareCheckedOptions = (checked, val) => {
    let temp = [];
    if (inspection.optionalHeaders.length > 0) {
      temp = inspection.optionalHeaders.map((m) => {
        let tempObj = m;
        if (m.key === val.key && m.categories.ident === inspection.tab.ident) {
          tempObj = { ...m, checked: checked };
        }
        return tempObj;
      });
    }

    if (
      !temp.length ||
      !temp.find(
        (f) => f.key === val.key && f.categories.ident === inspection.tab.ident
      )
    ) {
      temp.push({
        categories: inspection.tab,
        checked: checked,
        key: val.key,
        name: val.name,
      });
    }
    return temp;
  };

  const getCustomHeaderOptions = () => {
    let temp = headers.map((m) => {
      const isDisabledColumn = m.key === "qAndNumber" || m.key === "overdue";
      const isAlreadyInOptionalHeaders = inspection.optionalHeaders.some(
        (h) => h.key === m.key && h.categories.ident === inspection.tab.ident
      );

      if (isDisabledColumn && !isAlreadyInOptionalHeaders) {
        const autoSelect = prepareCheckedOptions(true, m);
        dispatch(setOptionalHeaders(autoSelect));
      }

      return (
        <ListItem
          key={m.key}
          style={{ width: "100%", whiteSpace: "pre-wrap" }}
          disablePadding
        >
          <ListItemIcon style={{ height: 56, paddingLeft: 10 }}>
            <Checkbox
              checked={
                isDisabledColumn ||
                inspection.optionalHeaders.some(
                  (h) =>
                    h.key === m.key &&
                    h.categories.ident === inspection.tab.ident &&
                    h.checked
                )
              }
              onChange={(e) => {
                if (!isDisabledColumn) {
                  onChecked(e.target.checked, m);
                }
              }}
              disabled={isDisabledColumn}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText
            id={m.key}
            primary={
              <Typography style={{ width: "100%", whiteSpace: "pre-wrap" }}>
                {m.name}
              </Typography>
            }
          />
        </ListItem>
      );
    });
    return temp;
  };
  return (
    <>
    <Card className="d-flex flex-column h-100">
      <CardContent className="p-0 w-100" style={{height: "calc(100% - 50px)", width:250}}>
        <DrawerHeader
          className="sub-header"
          style={{ minHeight: 50, height: 50, marginTop:1 }}
        >
          <IconButton
            style={{ padding: 4, marginLeft: "auto" }}
            onClick={(e) => handleDrawerOpen()}
          >
            {open ? (
              <NavigateBeforeIcon style={{ color: "#ffffff", fontSize: 30 }} />
            ) : (
              <NavigateNextIcon style={{ color: "#ffffff", fontSize: 30 }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Grow
          in={open}
          style={{ transformOrigin: "0 0 0" }}
          {...(open ? { timeout: 1000 } : { timeout: 1000 })}
        >
          <Drawer variant="permanent" open={open} 
           sx={{
            height: "calc(100% - 50px)",
            width: "100%",
            zIndex: 1500,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: "100%",
              boxSizing: "border-box",
            },
          }}>
        {open && (
        <List className="custom-scrollBar overflow-y-auto overflow-x-hidden w-100 h-100 p-1">
          {props.columnList.length > 0 && (
            <>
              <ListItem
                style={{ width: '100%', whiteSpace: 'pre-wrap' }}
                disablePadding
              >
                <ListItemIcon style={{ height: 56, paddingLeft: 10 }}>
                  <Checkbox
                    onClick={(e) => selectAll(e)}
                    checked={
                      numberOfChecked() === headers.length &&
                      headers.length !== 0
                    }
                    indeterminate={
                      numberOfChecked() !== headers.length &&
                      numberOfChecked() !== 0
                    }
                    disabled={headers.length === 0}
                    inputProps={{
                      'aria-label': 'all items selected',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={'select-all-01'}
                  primary={getIntlTranslation("_select all")}
                  secondary={`${numberOfChecked()}/${headers.length} selected`}
                />
              </ListItem>
              {getCustomHeaderOptions()}
            </>
          )}
        </List>
        )}
          </Drawer>
        </Grow>
        </CardContent >
        <CardActions className="p-0 mt-auto" style={{height:50}}>
          <Button

            onClick={() => {
              setOpenCustomHeader(true);
            }}
            style={{ backgroundColor: '#244a9a', padding: 5, width:"100%" }}
          >
            {open && (
              <Typography variant="body2" style={{color:"#ffffff"}}>{getIntlTranslation("_add-header")}</Typography>
                
            )}
            <Box>
              <AddIcon style={{ color: '#ffffff' }} />
            </Box>
          </Button>
         </CardActions>
      </Card>
      {openCustomHeader && (
        <ReorderAndHeaderDialog
          open={openCustomHeader}
          headerList={props.headerList}
          categories={props.categories}
          onClose={() => setOpenCustomHeader(false)}
        />
      )}
    </>
  );
}

ToolPanel.propTypes = {
  filterOptions: PropTypes.object,
  columnList: PropTypes.array,
  onFilterSelect: PropTypes.func,
  headerList: PropTypes.array,
  categories: PropTypes.array,
};

export default ToolPanel;
