import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Dialog,
  DialogContent,
  Box,
  FormControlLabel,
  Checkbox,
  AppBar,
  Toolbar
} from '@mui/material';
import { setOpenAsset } from './OpenAssetSlice';
import useAssetListHook from '../hooks/useAssetListHook';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarIcon from '@mui/icons-material/Star';

// Constants for event names - duplicated in each file that needs them
const DEFAULT_ASSET_CHANGED = 'defaultAssetChanged';
const DEFAULT_SUBASSET_CHANGED = 'defaultSubAssetChanged';

const SubAssetSwitcher = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const [selectedAsset, setSelectedAsset] = useState({});
  const [assets, setAssets] = useState([]);
  const [showSelectionModal, setShowSelectionModal] = useState(true);
  const [makeDefaultSubAsset, setMakeDefaultSubAsset] = useState(false);
  const openAsset = useSelector((state) => state.openAsset);
  const dispatch = useDispatch();
  const { assetList, fetchList } = useAssetListHook();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDefaultSubAsset, setIsDefaultSubAsset] = useState(false);

  useEffect(() => {
    if (assetList.isFetchSuccess && assetList.assets.length > 0) {
      try {
        // Get default sub-asset from localStorage
        const storedDefaultSubAsset = localStorage.getItem('defaultSubAsset');
        let defaultSubAssetData = null;
        if (storedDefaultSubAsset) {
          defaultSubAssetData = JSON.parse(storedDefaultSubAsset);
        }

        const flattenedAssets = assetList.assets.flatMap(asset => {
          return asset.subAssetListDto
            .filter(sub => !!sub.subAssetIdent)
            .map(sub => ({
              asset: asset.name,
              assetIdent: asset.assetIdent,
              label: sub.name,
              value: sub.name,
              subAssetIdent: sub.subAssetIdent,
              subAssetType: sub.subAssetType,
              isDefault: defaultSubAssetData?.subAssetIdent === sub.subAssetIdent &&
                        defaultSubAssetData?.assetIdent === asset.assetIdent &&
                        defaultSubAssetData?.projectIdent === scope.projectIdent
            }));
        });

        setAssets(flattenedAssets);

        // If there's a default sub-asset for this project, select it automatically
        if (defaultSubAssetData?.projectIdent === scope.projectIdent) {
          const defaultAsset = flattenedAssets.find(
            a => a.subAssetIdent === defaultSubAssetData.subAssetIdent
          );
          
          if (defaultAsset) {
            handleAssetSelect(defaultAsset, false);
            setShowSelectionModal(false);
          }
        }
      } catch (error) {
        console.error('Error loading assets:', error);
      }
    } else if (!assetList.isFetchSuccess) {
      fetchList();
    }
  }, [assetList, scope.projectIdent, fetchList]);

  // Listen for default changes
  useEffect(() => {
    const handleDefaultSubAssetChange = (event) => {
      if (event.detail?.subAssetIdent) {
        // Update assets list with new default
        setAssets(prev => prev.map(asset => ({
          ...asset,
          isDefault: asset.subAssetIdent === event.detail.subAssetIdent
        })));
        
        // Update selected asset if it matches
        if (selectedAsset?.subAssetIdent === event.detail.subAssetIdent) {
          setIsDefaultSubAsset(true);
        }
      } else if (event.detail?.removed) {
        // Default was removed
        setAssets(prev => prev.map(asset => ({
          ...asset,
          isDefault: false
        })));
        setIsDefaultSubAsset(false);
        
        // Immediately hide selection modal if it was open
        if (showSelectionModal) {
          setShowSelectionModal(false);
        }
      }
    };

    // Handle when default asset changes (may affect sub-asset)
    const handleDefaultAssetChange = (event) => {
      if (event.detail?.removed) {
        // If default asset is removed, check if it affects the sub-asset
        if (selectedAsset?.assetIdent === event.detail?.previousAssetIdent) {
          setIsDefaultSubAsset(false);
        }
        
        setAssets(prev => prev.map(asset => {
          if (asset.assetIdent === event.detail?.previousAssetIdent) {
            return { ...asset, isDefault: false };
          }
          return asset;
        }));
      } 
      // For force updates from asset switcher
      else if (event.detail?.force) {
        // Check if the default asset has changed
        const storedDefaultAsset = localStorage.getItem('defaultAsset');
        if (storedDefaultAsset) {
          const defaultAsset = JSON.parse(storedDefaultAsset);
          
          // If the current selected asset is not from the new default asset, clear selection
          if (selectedAsset?.assetIdent && selectedAsset.assetIdent !== defaultAsset.ident) {
            setSelectedAsset({});
            setIsDefaultSubAsset(false);
            
            // Refresh the assets list - this may trigger the selection modal
            fetchList();
            
            // If we're currently showing the modal, force update to reflect new defaults
            if (showSelectionModal) {
              // Set timeout to allow the state to update first
              setTimeout(() => {
                setShowSelectionModal(true);
              }, 0);
            }
          }
        }
      }
    };

    window.addEventListener(DEFAULT_SUBASSET_CHANGED, handleDefaultSubAssetChange);
    window.addEventListener(DEFAULT_ASSET_CHANGED, handleDefaultAssetChange);
    
    return () => {
      window.removeEventListener(DEFAULT_SUBASSET_CHANGED, handleDefaultSubAssetChange);
      window.removeEventListener(DEFAULT_ASSET_CHANGED, handleDefaultAssetChange);
    };
  }, [selectedAsset, showSelectionModal, fetchList]);

  const handleAssetSelect = (asset, shouldUpdateLocalStorage = true) => {
    if (props.dirtyState) {
      props.setBlockState({
        assetName: asset.asset,
        ident: asset.assetIdent,
        subAssetName: asset.label,
        subAssetIdent: asset.subAssetIdent
      });
    } else {
      setSelectedAsset(asset);
      setIsDefaultSubAsset(asset.isDefault);

      if (shouldUpdateLocalStorage && makeDefaultSubAsset) {
        // Set default sub-asset in localStorage
        localStorage.setItem('defaultSubAsset', JSON.stringify({
          assetIdent: asset.assetIdent,
          subAssetIdent: asset.subAssetIdent,
          name: asset.label,
          projectIdent: scope.projectIdent,
          timestamp: new Date().getTime()
        }));

        // Also set the parent asset as default
        localStorage.setItem('defaultAsset', JSON.stringify({
          ident: asset.assetIdent,
          name: asset.asset,
          projectIdent: scope.projectIdent,
          timestamp: new Date().getTime()
        }));

        // Notify about asset default change
        window.dispatchEvent(new CustomEvent(DEFAULT_ASSET_CHANGED, {
          detail: {
            ident: asset.assetIdent,
            name: asset.asset,
            projectIdent: scope.projectIdent
          }
        }));
        
        // Notify about sub-asset default change
        window.dispatchEvent(new CustomEvent(DEFAULT_SUBASSET_CHANGED, {
          detail: {
            assetIdent: asset.assetIdent,
            subAssetIdent: asset.subAssetIdent,
            name: asset.label
          }
        }));
      }

      dispatch(
        setOpenAsset({
          assetName: asset.asset,
          ident: asset.assetIdent,
          subAssetName: asset.label,
          subAssetIdent: asset.subAssetIdent,
          isDefault: shouldUpdateLocalStorage && makeDefaultSubAsset,
          isSubAssetDefault: shouldUpdateLocalStorage && makeDefaultSubAsset
        })
      );
    }

    setShowSelectionModal(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSetDefault = () => {
    if (!selectedAsset?.subAssetIdent) return;
    
    try {
      // Set default sub-asset
      localStorage.setItem('defaultSubAsset', JSON.stringify({
        assetIdent: selectedAsset.assetIdent,
        subAssetIdent: selectedAsset.subAssetIdent,
        name: selectedAsset.label,
        projectIdent: scope.projectIdent,
        timestamp: new Date().getTime()
      }));

      // Set parent asset as default too
      localStorage.setItem('defaultAsset', JSON.stringify({
        ident: selectedAsset.assetIdent,
        name: selectedAsset.asset,
        projectIdent: scope.projectIdent,
        timestamp: new Date().getTime()
      }));
      
      setIsDefaultSubAsset(true);
      
      // Update local assets state
      const updatedAssets = assets.map(asset => ({
        ...asset,
        isDefault: asset.subAssetIdent === selectedAsset.subAssetIdent
      }));
      setAssets(updatedAssets);

      // Update Redux state with both flags
      dispatch(
        setOpenAsset({
          ...openAsset,
          isDefault: true,
          isSubAssetDefault: true
        })
      );

      // Notify about both changes
      window.dispatchEvent(new CustomEvent(DEFAULT_ASSET_CHANGED, {
        detail: {
          ident: selectedAsset.assetIdent,
          name: selectedAsset.asset,
          projectIdent: scope.projectIdent
        }
      }));
      
      window.dispatchEvent(new CustomEvent(DEFAULT_SUBASSET_CHANGED, {
        detail: {
          assetIdent: selectedAsset.assetIdent,
          subAssetIdent: selectedAsset.subAssetIdent,
          name: selectedAsset.label
        }
      }));
    } catch (error) {
      console.error('Error setting default:', error);
    }
    
    handleMenuClose();
  };

  const handleRemoveDefault = () => {
    try {
      // Store the asset ident before removal for event notification
      const previousAssetIdent = selectedAsset?.assetIdent;
      
      // Remove from localStorage
      localStorage.removeItem('defaultSubAsset');
      localStorage.removeItem('defaultAsset');
      
      // Update local state
      setIsDefaultSubAsset(false);
      
      // Update assets list
      const updatedAssets = assets.map(asset => ({
        ...asset,
        isDefault: false
      }));
      setAssets(updatedAssets);
      
      // Update Redux state
      dispatch(
        setOpenAsset({
          ...openAsset,
          isDefault: false,
          isSubAssetDefault: false
        })
      );
      
      // Notify about both changes
      window.dispatchEvent(new CustomEvent(DEFAULT_ASSET_CHANGED, {
        detail: {
          ident: null,
          removed: true,
          previousAssetIdent
        }
      }));
      
      window.dispatchEvent(new CustomEvent(DEFAULT_SUBASSET_CHANGED, {
        detail: {
          subAssetIdent: null,
          removed: true
        }
      }));
      
      handleMenuClose();
    } catch (error) {
      console.error('Error removing default:', error);
    }
  };

  return (
    <>
      <Dialog
        open={showSelectionModal && assets.length > 0}
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
      >
        <AppBar position="static" style={{ backgroundColor: '#d08f8e' }}>
          <Toolbar style={{ minHeight: '48px' }}>
            <div>Select Sub-Asset</div>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box className="d-flex flex-column gap-2" style={{ marginTop: '15px' }}>
            {assets.map((asset) => (
              <Box
                key={asset.subAssetIdent}
                className="p-2 border rounded cursor-pointer"
                onClick={() => handleAssetSelect(asset)}
                style={{
                  backgroundColor: asset.isDefault ? '#fafad2' : 'white',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#f5f5f5'
                  }
                }}
              >
                {asset.asset} - {asset.label}
                {asset.isDefault && (
                  <StarIcon fontSize="small" style={{ color: '#FFD700', marginLeft: '5px' }} />
                )}
              </Box>
            ))}
            <FormControlLabel
              control={
                <Checkbox
                  checked={makeDefaultSubAsset}
                  onChange={(e) => setMakeDefaultSubAsset(e.target.checked)}
                  color="primary"
                />
              }
              label="Make selected sub-asset default"
              className="mt-2"
            />
          </Box>
        </DialogContent>
      </Dialog>

      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Autocomplete
          id="subAssetOptions"
          size="small"
          style={{ margin: 5, flexGrow: 1 }}
          value={selectedAsset}
          options={assets}
          groupBy={(option) => option.asset}
          onChange={(event, newValue) => {
            if (newValue?.subAssetIdent) {
              handleAssetSelect(newValue);
            }
          }}
          getOptionLabel={(option) => option.label || ''}
          renderOption={(props, option) => (
            <li {...props}>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <span style={{ flexGrow: 1 }}>{option.label}</span>
                {option.isDefault && (
                  <Tooltip title="Default Sub-Asset">
                    <StarIcon fontSize="small" style={{ color: '#FFD700' }} />
                  </Tooltip>
                )}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={props.variant}
              label="Sub-Asset"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {selectedAsset?.subAssetIdent && isDefaultSubAsset && (
                      <Tooltip title="Default Sub-Asset">
                        <StarIcon fontSize="small" style={{ color: '#FFD700' }} />
                      </Tooltip>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />

        {selectedAsset?.subAssetIdent && (
          <>
            <IconButton
              size="small"
              onClick={handleMenuOpen}
              style={{ marginLeft: '5px' }}
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {isDefaultSubAsset ? (
                <MenuItem onClick={handleRemoveDefault}>
                  Remove as Default Sub-Asset
                </MenuItem>
              ) : (
                <MenuItem onClick={handleSetDefault}>
                  Set as Default Sub-Asset
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </div>
    </>
  );
};

SubAssetSwitcher.defaultProps = {
  variant: 'outlined',
  dirtyState: false,
};

SubAssetSwitcher.propTypes = {
  variant: PropTypes.string,
  style: PropTypes.object,
  dirtyState: PropTypes.bool,
  setBlockState: PropTypes.func,
  needGridRefresh: PropTypes.func,
};

export default SubAssetSwitcher;