/*

Table of Contents
1. menu items
*/

module.exports = {
  /* 1.Menu Items */
  "menu.home": "Home",
  "menu.user-management": "User management",
  "menu.data-collection": "Data collection",
  "menu.create-project": "Create project",
  "menu.tdd-management": "TDD",
  "menu.pm-management": "Project monitoring",
  "menu.esg-management": "ESG",
  "menu.tam-management": "TAM",
  "menu.project-list": "Projects",
  "menu.user-dashboard": "User dashboard",
  "menu.user-profile": "User profile",
  "menu.project-dashboard": "Project dashboard",
  "menu.project-description": "Project description",
  "menu.project-information": "Project information",
  "menu.asset-overview": "Asset overview",
  "menu.building-profile": "Building profile",
  "menu.defects": "Defects",
  "menu.photo-documentation": "Photo documentation",
  "menu.capexOverview": "Capex overview",
  "menu.redFlag": "Red Flag",
  "menu.fullReport": "Full Report",
  "menu.statutoryCompliance": "Statutory Compliance",
  "menu.customReport": "Custom Report",
  "menu.infra-management": "Infrastructure",
  "menu.general-assessment": "General assessment",
  "menu.key-findings": "Key findings",
  "menu.project-progress": "Project progress",
  "menu.project-variations": "Variations",
  "menu.project-documentation": "Documentation",
  "menu.project-conclusion": "Conclusion",
  "menu.task-management": "Task management",
  "menu.cost-dataRoom": "Cost data room",
  "tag.fact-sheet": "Fact sheet",
  "tag.summary": "Summary",
  "tag.progress": "Progress",
  "tag.others": "Others",
  "tag.overview": "Overview",
  "menu.review": "Review",
  "menu.client-management": "Client Management",
  "menu.data-analysis": "Data - Analysis",
  "menu.preview-document": "Preview Document",
  "menu.chat": "Chat",

  /* 2. header Items */

  "header.handbookDownload": "Please click to download the user guide",
  /* 3. Authentication Items */

  "user.login": "Login",
  "user.password": "Password",
  "user.confirmPassword": "Confirm password",
  "user.emailAddress": "E-mail",
  "user.emailRequired": "Email is required",
  "user.passwordRequired": "Password is required",
  "user.forgotPassword": "Forgot password?",
  "user.username": "Username",
  "user.invalidEmail": "Invalid email adress",
  "user.usernameRequired": "Username is required",
  "user.firstnameRequired": "First name is required",
  "user.lastnameRequired": "Last name is required",
  "user.languageRequired": "User language is required",
  "user.resetPassword": "Reset password",
  "user.firstName": "First name",
  "user.lastName": "Last name",
  "user.contact": "Contact",
  "user.contactNumber": "Telephone number",
  "user.work": "Work",
  "user.title": "Title",
  "user.office": "Office location",
  "user.systemLanguage": "System language",
  "user.language": "Language",
  "user.account": "Account",
  "user.signOut": "Sign out",
  "user.role": "User role",
  /* 4. Guru Messages*/

  "guru.welcome": "Welcome to TA Guru",
  "guru.messages": "A product of TA Europe Analytics GmbH",

  /* 5. common */
  "project-overview": "Project overview",
  required_field: "This field is required",
  _projects: "Projects",
  "_no records": "No records to display",
  _search: "Search",
  _generalInformation: "General information",
  "Project Name": "Project name",
  "_project-number": "Project number",
  "Project Description": "Project description",
  "Project Threshold": "Threshold",
  "team_members_info": "Only Project Leaders and Project Assistants can add new team members.",

  _language: "Language",
  "Type Of Report": "Report Type",
  "Client Information": "Client information",
  "Client Address Detail": "Client address detail",
  "Client Name": "Client name",
  _Address: "Adresse",
  _City: "City",
  "_zip-code": "Zip code",
  _State: "State",
  _Country: "Country",
  "Add Users TO Project": "Add user to the project",
  _status: "Status",
  "_comments-Ta": "Comment TA Europe",
  _start: "Start",
  _finish: "Finish",
  "Team Members": "Team members",
  "Project Information": "Project information",
  add_team_member: "Would you like to add team members to this project",
  _trigger_team_member_add_email:
    "Would you like to send email to team members?",
  "create user": "Create user",
  _Asset: "Asset",
  "Address Detail": "Address data",
  "_building type": "Building type",
  "_select asset": "Select asset",
  report_version: "Report version",
  Asset: "Asset",
  User: "User",
  "_Asset Information": "Asset information",
  "_Sub-Asset Information": "Sub-asset information",
  _Name: "Name",
  "_Building Count": "Number of buildings",
  "_SubAsset Count": "Number of sub-assets",
  "_Address Details": "Address details",
  _building: "Building",
  _Type: "Type",
  "_Email Address": "E-mail address",
  _email: "E-Mail",
  _position: "Position",
  _Permission: "Permission",
  _Author: "Author",
  _quelle: "Source",
  "_select all": "Select all",
  _Subasset: "Sub-asset",
  _general: "General",
  "_marked-general": "(Marked as general)",
  "_same-as-asset": "Same as asset",
  _description: "Description",
  "_Regional Factor": "Regional factor",
  "_Renovation Year": "Year of renovation (if applicable)",
  "_create-report-version": "Create version",
  categories: "Categories",
  _item_name: "Item Name",
  _item_type: "Item Type",
  "_my-projects": "My projects",
  "_project-library": "Project Library",
  "_type-user-search": "Type to search user",
  "_building-profile": "Building profile",
  _defects: "Defects",
  "_photo-documentation": "Photo documentation",
  "_start-date": "Start Date",
  "_end-date": "End Date",
  "_trade-information": "Trade Information",
  "_add-filters": "Add Filters",
  _trade: "Trade",

  /* 6. messages*/
  _saved: "Information has been saved successfully",
  "search google maps": "Search on google maps",
  "short project summary": "Please create a short summary of the project",
  "comparison mode option": "Would you like to compare with the last version?",
  "current version": "Current version",
  version: "Version",
  _compare: "Comparing",
  _properties: "Items",
  "Optional Properties": "Optional items",
  "Add Custom Properties": "Add custom user defined items",
  add_custom_properties_placeholder: "Pleas click on " + " to add a new item",
  "_leave warning": "Are you sure you want to leave?",
  "_unsaved changes": "You have unsaved changes as follows:",
  "_no-changes": "No changes found, all is up to date",
  "_action prompt":
    "Click 'back' to return to the existing page or click 'continue' to ignore unsaved changes and continue.",
  "List of Sub-Assets": "List of sub-assets",
  "_Create Another Asset": "Create another asset",
  "_Mark as complete": "Mark as complete",
  "_Construction Year": "Construction year",
  "mark-report-verison-current":
    "Do you want to mark this version as a current version?",
  no_data_click_start:
    "No entries could be found, please click here to create a new entry",
  "_existing property": "Existing item",
  "_existing categories": "Existing categories",
  "_no existing property":
    "No existing items could be found. please create a new one if needed.",
  "_property not found":
    "The item was not found in this version of the report.",
  _unsaved_changes: "You have unsaved changes on :",
  "_requested-service":
    "Sorry we are not able to provide you the requested service.",
  "_report-selection": "Report selection",
  "_Capex-alert":
    "This asset contains defects for which the CAPEX ID does not exist. Could you please create the CAPEX again?",
  "_report-type": "Report type",
  "_report-language": "Report language",
  "_report-version-type": "Report version type",
  "_appendix-required": "Appendix Required",
  "_include-appendix": "Include Appendix",
  _Generate: "Generate",
  "_generate-report": "Generate report",
  "_Cost-Defect": "Cost defect",
  _Defect: "Defect",
  _cancel: "Cancel",
  "_Choose-Element-Type": "Choose element type",
  "_Element-Name": "Element name",
  "_element-type": "Element Type",
  "_Element-Construction-Year": "Element construction year",
  "_Cost-Data": "Cost data",
  "_cost-estimates": "Cost parameter",
  _from: "Minimum",
  _middle: "Average",
  _upto: "Maximum",
  _cost: "Costs",
  "_Q&A": "Q&A",
  "_created-by": "Created by",
  _Comments: "Comments",
  "_Data-Room-Index": "Data room index",
  _Copy: "Copy",
  "_Delete-Image": "Delete image",
  "_Save-Image": "Save image",
  "_Non-Cost-Defect": "Non cost defect",
  "_Choose-Risk-Type": "Choose a risk type",
  "_risk-Type": "Risk type",
  _risk: "Risk",
  "_Defect-Description": "Defect description",
  "_defect-and-measure": "Defect & Measure",
  "_Measure-Description": "Measure description",
  "_Choose-a-Defect-Type": "Choose a defect type",
  "_Defect-Type": "Defect type",
  "_Choose-a-Priority-Type": "Choose a priority type",
  "_Priority-Type": "Priority type",
  _Priority: "Priority",
  "_Short-Term": "Short term",
  "_Medium-Term": "Medium term",
  "_Long-Term": "Long term",
  Multiple: "Multiple",
  "no cost proposal": "No cost proposals available",
  Comments: "Comments",
  "_Extra-Cost-Comments": "Comment (internal)",
  "_Extra-Cost-(Optional)": "Extra cost (optional)",
  "_Extra-Cost": "Extra cost",
  "_Cost-estimate-for-CAPEX": "Cost estimate for capex",
  _Total: "Total",
  "% probability of occurrence": " % probability of occurrence",
  "_%-of-the-element-affected": " % of the element affected",
  "Project Factor": "Project factor",
  _Quantity: "Quantity",
  _Unit: "Unit",
  "_Price-per-Unit": "Price per unit",
  "_min-value-1-and-max-value-100": "Min value 1 and max value 100",
  "Building Count": "Number of buildings",
  "SubAsset Count": "Number of sub-assets",
  _threshold:
    "The threshold for cost defect is € 10,000. You can still create the defect but please talk to the project manager about this defect.",
  "_cost-suggestion-available": "Cost proposals available",
  "_Create-Another-Defect": "Create another defect",
  "_Our-Recommendation-For": "Our recommendation for",
  _AddToRedFlag: "Mark as a significant risk",
  "_AddToRedFlag-Warning-1": "You have selected",
  "_AddToRedFlag-Warning-2":
    "Risk for this defect. It is recommended not to mark such defects as significant risk.",
  _Low: "Low",
  "_Select-sub-Asset-to-copy": "Select sub-asset to copy",
  "_List-of-Sub-Assets": "List of sub-assets",
  "_Drag-Images": "Drag and drop some files here or click to select files.",
  "_Image-Allowed":
    "(Only *.jpeg and *.png images are accepted, please avoid uploading portrait images)",
  "_Capex-Needs-To-Be-Generated": "Capex needs to be generated",
  _Restore: "Restore",
  "_drop-the-files": "Please drop the files here",
  "_No-Defect-Images": "No defect images",
  "_Save-All": "Save all",
  "_General-Photos": "General photos",
  "_Defect-Photos": "Defect photos",
  "_general-sub-assets": "Do you want to mark this sub-asset as general?",
  "_general-sub-assets-info":
    "You can use a general sub-asset to add common data for all sub-assets or summarize the data for all sub-assets.",
  "_individual-info-sub-assets":
    "Do you want to add information in individual sub-assets?",
  "_no-capex-data":
    "Please check if assets have been created for this project or if the capex data has already been created.",
  "_Save-All-Images": "Save all images",
  "_capex-overview": "CAPEX overview",
  "_Add-new-label": "Add a new label",
  "_missing-label": "Did you miss any label in our list? please add it!",
  "_add-comment": "Add a comment...",
  "_no-data": "Sorry, there is no data available for the selected option.",
  "_English-Translation": "English Translation",
  "_German-Translation": "German Translation",
  "_optional-deault-text": "Default text (optional)",
  "_Add contact": "Add to contact",
  "_Remove contact": "Remove from contact",
  "_Data is missing": "No data, please update",
  _Mobile: "Mobile ",
  "_Office Name": "Office",
  "_max contact allowed": "Max allowed contact is 4",
  _history: "History",
  "_max files allowed":
    "Sorry,  The maximum number of files that can be uploaded is!",
  "_No Rows": "No rows were found matching the filter criteria",
  "_no-report":
    "You may not have any assets in this project, please return to the menu asset-overview to create a new asset.",
  "_add-headers": "Add headers for ",
  _headers: "Headers",
  "_Header Name": "Header name",
  "_add-header button": "Add a custom header",
  "_header added":
    "Your new header has been added to the list of headers below:",
  "_header exists":
    "The header name you gave already exists please give a non-existent header name",
  "_Drag and drop header":
    "Drag and drop the header to rearrange your header table.",
  "_Please not empty":
    "Please make sure the trade name is not empty. trade numer is required.",
  _Rearrange: "Rearrange header or add a new header",
  _Internal: "Internal",
  _Client: "Client",
  "_report language": "Select a report language",
  "_Report Selection": "Report selection",
  "_report type": "Select a report",
  "_report-version": "Report version",
  "_Select Headers": "Please select headers to appear in report",
  "_Save or select": "Save or select existing headers",
  "_Select Sub-Asset": "Please select sub-asset to view data",
  "_add-header": "Add header",
  "Last Change": "Last updated at",
  "_Asset-User-Management": "Asset user management",
  "_Inflation rate": "Inflation rate suggestion",
  "_percent symbol": "Enter the number without the percent symbol",
  _Expand: "Expand",
  _Collapse: "Collapse",
  "_view-all": "View all",
  "_Search Costs": "Search costs",
  "_Search Task": "Search task",
  "_filter-category": "Filter by category",
  "_Inflation Rate": "Inflation rate for all cost",
  "_Download Cost element": "Download cost element",
  "_Upload cost elment": "Import excel cost elment",
  "_Add Child": "Add cost",
  "_Add Sibling": "Copy cost",
  "_Single Inflation Rate": "Update a single inflation rate",
  "_sorry-request": "Sorry we are not able to fulfill your request",
  no_asset_found_click_start:
    "We couldn't find any assets / sub-assets in this project or assigned to you. Please click here to start creating assets or request write access from the project assistant.",
  "_mark as current": "Mark as current",
  "_edit version name": "Edit version name",
  "_mark as temp version": "Mark as temp version",
  "_delete report version": "Delete report version",
  "_delete report version-1": "Are you sure you want to delete the report version",
  "_delete report version-2": "This action cannot be undone.",
  "_confrim-deletion": "Confirm Deletion",
  "cancel": "CANCEL",
  "delete": "DELETE",
  "_undo temp version": "Undo temp version",
  "_temp alert":
    "You are currently working on a temp version at asset: {asset_name} version: {version_name}",
  "_select sub-asset-to-start": "Please select the sub-asset to get started",
  _technical_issues:
    "Unfortunately we are experiencing some technical issues with sending emails. please contact guru@taeurope.com for a quick solution.",
  _readOnly_mode:
    "The project is open in read-only mode with restricted access.",
  "_show all": "All cost proposals",
  _unsave_copy_action_changes: "Unsaved Copied Data",
  "_unsaved-data": "Not Saved Changes",
  "_delete-alert-text": "Are you sure you want to delete the following:",
  "_question-mark": "?",
  _warning: "Warning",
  _mark_row_delete:
    "The row has been marked as deleted. Please click on 'Save' to save your changes.",
  "_label-required": "The label must not be empty",
  "_all-comments": "Comments",
  "_show-all-comments": "View all comments",
  "_forgot-password": "Forgot password?",
  "_enter-your-email":
    "Enter your email address and we will send you a link to log back into your account.",
  "_passwords-match": "Both Passwords should match",
  "_valid-password-format":
    "The password must be at least 6 characters long and consist of a combination of upper and lower case letters, numbers and special characters.",
  _reset_inavlid_taken:
    "The password reset link seems to have expired. You can get a new link at",
  _replies: "answers",
  _reply: "Reply",
  _edited: "(edited)",
  "_add-comments": "Add comments",
  _timeline: "Timeline",
  "_changes-from": "Changes From",
  "_current-text": "Current Text",
  "_no-changes-found": "No changes found",
  "_client-list": "Client List",
  _or: "oder",
  "_custom-operation": "Custom Operation",
  "_cost-suggestion": "Cost proposals",
  "_cost-suggestion-not-available":
    "Unfortunately, we do not have any cost suggestions for your selection.We ask for your valuable assistance in reporting the missing data by emailing us at guru@taeurope.com.",
  "_cost-suggestion-select-defect-measure":
    "Please select defect and measure to see the cost proposal.",

  "_short-term": "Short Term (Year 1)",
  "_medium-term": "Medium Term (Year 2 - 5)",
  "_long-term": "Long Term (Year 6 - 10)",
  _bau: "Building Construction",
  _tga: "Technical Building Equipment",
  "_outdoor-facilities": "Outdoor facilities",
  "_fire-life-safety": "Fire, life & safety protection",
  _confirmity: "Conformity",
  _lifecycle: "Life cycle",
  _defect: "Defect",
  _prevention: "Prevention",
  "_confirmity-defect": "Conformity",
  _projectRedirect: "Open project",
  "_area-applicable":
    "Building Profile Areas (Please only enter the available area information and leave the remaining fields blank)",
  "_parking-applicable":
    "Building profile Parking spaces (Please enter only the available parking spaces and leave the remaining fields blank)",
  "_floor-applicable":
    "Building profile floors (Please enter only the available floor information and leave the other fields blank)",
  "_existing-structure": "Existing Structure",
  "_technical-lifecycle": "Technical service life [years]",
  "_type-of-element": "Type of element",
  "_no-data-available": "No data available",
  _review: "Review",
  "_reopen-thread": "Reopen",
  "_delete-thread": "Delete Thread",
  "_edit-comment": "Edit Comment",
  "_resolve-threads": "Resolved Threads",
  "_resolve-thread": "Resolve Thread",
  "_open-threads": "Open Threads",
  "_no-comments-found": "No comments found",
  "_no-comments-create":
    "No comments found, You can start creating one by clicking on the icon.",
  _markComplete: "Mark the review complete",
  "_type-comments": "Type your comment here...",
  _post: "Post",
  "_no-contacts":
    "No contacts have been selected, please select users for contact information.",
  "_total-images-uploaded": "Images uploaded",
  "_comments-assigned": "Comments Assigned (open)",
  "_numeric-format": "Input is auto-formatted to",
  "_unknown-value": "Use '0' if unknown.",
  "_open-images": "Open Images",
  _selected: "Selected",
  "_text-editor-placeholder": "Start typing your text here...",
  "_checkbox-copy-data":
    "Please select the checkbox to add the data to the current version",
  /* 7. buttonText  */

  "_download-template": "Download Template",
  _create: "Create",
  _save: "Save",
  add: "Add",
  register: "Register",
  "_Create Asset": "Create asset",
  "_create another": "Create another Asset",
  "_Create Sub-Asset": "Create sub-asset",
  _edit: "Edit",
  "_Edit User": "Edit user",
  _delete: "Delete",
  "_Begin Full Report": "Begin full report",
  "_Asset Template": "Asset template",
  "_Defect Template": "Defect template",
  "_Upload Asset File": "Upload asset file",
  "_Upload Defect File": "Upload defect file",
  "_add-properties": "Add item",
  "_add-row": "Add row",
  _copy: "Copy",
  _back: "Back",
  _continue: "Continue",
  "_create-category": "Create category",
  "_redirect-to-asset-overview": "Redirect to asset-overview",
  "_add-more": "add more",
  "_show-more": "Show more",
  _close: "Close",
  _submit: "Submit",
  _reset: "Reset",
  "_remove-all": "Remove all",
  "_apply-filters": "Apply filters",
  "_sort-by-asc": "Sort by ASC",
  "_sort-by-desc": "Sort by DESC",
  "_capex-summary": "Capex Summary",
  _certificate: "CERTIFICATE",
  _isChecked: "Available",
  _isNotChecked: "Not available",
  _feedback: "Give feedback to Team Guru",
  "_user-editable": "Editable by the user",
  _select: "Select",
  _translation: "Translation",
  "_send-email": "Send Email",

  /* 8. tooltip */
  "tooltip.edit-asset": "Click to edit the asset",
  "tooltip.delete-asset": "Click to delete the asset",
  "tooltip.create-report-verion":
    "Click to create and start a new report version",
  "tooltip.create-asset": "Click to create asset",
  "tooltip.add-row": "Click to add row",
  "tooltip.edit": "Click to edit",
  "tooltip.save": "Click to save the changes",
  "tooltip.delete": "Click to delete",
  "tooltip.Create Sub-Asset": "Click to create sub-asset",
  "tooltip.Delete sub-Asset": "Click to delete sub asset",
  "tooltip.open_reports_dialog": "Click to view report options",
  "tooltip.Begin-Full-Report": "Click to start the full reports",
  "tooltip.Download-Asset-Template":
    "Click to download template for asset file",
  "tooltip.Download-Defect-Template":
    "Click to download template for defect file",
  "tooltip.Upload-Asset-File": "Click to upload an asset file",
  "tooltip.Upload-Defect-File": "Click to upload an defect file",
  "tooltip.cancel": "Click to cancel",
  "tooltip.copy": "Click to copy",
  "tooltip.Save-Building-Information": "Save building information",
  "tooltip.click-to-generate": "Click to generate",
  "tooltip.close-defect-dialog": "Close defect dialog",
  "tooltip.Save-Defect": "Save defect",
  "tooltip.create-Defect": "Create defect",
  "tooltip.Click-to-close": "Click to close",
  "tooltip.Copy-Defects": "Copy defects",
  "tooltip.Save-All": "Save all",
  "tooltip.Click-to-paste-selected-rows": "Click to paste selected rows",
  "tooltip.Save-Image": "Save image",
  "tooltip.Delete-Image": "Delete image",
  "tooltip.add-custom": "Click to add user-defined values",
  "tooltip.Save-All-Images": "Click here to save all images",
  "tooltip.Save": "Click here to save",
  "tooltip.Save-report-Text": "Click to save the report text",
  "tooltip.save-the-screenshot": "Click to save the screenshot of the location",
  "tooltip.leaving-warn": "Click if you are sure you want to leave.",
  "tooltip.create-project": "Click to create a project",
  "tooltip.redirect-to-asset-overview": "Click to redirect to asset-overview",
  "tooltip.reorder": "Click to reorder the items",
  "tooltip.asset-user-management": "Click to manage asset users",
  "tooltip.remove": "Click to delete",
  "tooltip.show-more-details": "Click here to view the costs in detail",
  "dialog.delete-Asset": "Delete asset",
  "dialog.Update-Asset": "Update asset",
  "tooltip.add-Image": "Click to add images",
  "tooltip.add-comments": "Click to add comments",
  "tooltip.show-all": "Click to view all",
  "tooltip.reply": "Click to reply",
  "tooltip.fullscreen": "Click to open full view mode",
  "tooltip.menu": "menu",
  "tooltip.switch-to-client-list": "Switch to the client list view",
  "tooltip.switch-to-project-list": "Switch to the project list view",
  "tooltip.remove-all": "Click to Remove All",
  "tooltip.apply-all": "Click to Apply All",
  "tooltip.open": "Click to open",
  "tooltip.help-us-improve": "Help Improve Guru",
  "tooltip.add-structure": "Click to add the structure",
  "tooltip.add-header": "Click to add the header",
  "tooltip.preview-mode": "Click to open preview mode",
  "tooltip.send-email": "Click to send email",
  "tooltip.review-mode": "Click to open review mode",
  "tooltip.comments-assigned": "Comments assigned to you",
  "tooltip.reload": "Click to refresh data",

  /* Chat with PDF + Preview Document */
  "chat-with-pdf.title": "Chat with PDF",
  "chat-with-pdf.question": "Ask a question",
  "chat-with-pdf.send": "Send",
  "chat-with-pdf.greetings": "How can I help you today?",

  "chat-with-pdf.warning-not-searchable-by-text":
    "This PDF is not searchable by text, so we cannot parse it. Please try with a searchable PDF. We are in the testing phase for now.",

  "chat-with-pdf.warning-large-token":
    "Since we are in the testing phase, this PDF has a large token size and we cannot parse through it. Please try with a smaller PDF.",

  "preview-document.select": "Please select a file to preview",

  /* Define Risks Types */
  "low-warning":
    "Defects/problems that do not require immediate action or clarification, as well as life cycle problems that should be considered in the future maintenance program, as the end of the expected service life will be reached in the period under consideration.",
  "medium-warning":
    "Defects/problems that require immediate action or clarification because the functionality/usability of the property is at risk, as well as overdue lifecycle problems (significant maintenance backlog) because the end of the expected lifespan has been exceeded. Risks of such a financial magnitude that they affect the value of the property and should be addressed in the short to medium term after the transaction is completed.",
  "high-warning":
    "Defects that require immediate attention and clarification as either health and/or safety, including structural safety, fire protection and compliance with building laws/regulations, are at risk. Therefore, such issues should be clarified/resolved prior to or as part of the transaction.",

  // High risk translations
  "high-warning-start":
    "Defects that require immediate attention and clarification as either",
  health: "health",
  "and-or": "and / or",
  safety: "safety",
  including: "including",
  "structural-safety": "structural safety",
  "fire-protection": "fire protection",
  "building-laws": "compliance with building laws",
  "high-warning-end":
    "regulations, are at risk. Therefore, such issues should be clarified / resolved prior to or as part of the transaction.",

  // Medium risk translations
  "medium-warning-start":
    "Defects / problems that require immediate action or clarification because the",
  "functionality-usability": "functionality / usability",
  is: "of the property is",
  "at-risk": "at risk",
  "as-well-as": "as well as",
  "overdue-lifecycle": "overdue lifecycle",
  "medium-warning-end":
    "problems (significant maintenance backlog) because the end of the expected lifespan has been exceeded. Risks of such a financial magnitude that they affect the value of the property and should be addressed in the short to medium term after the transaction is completed.",

  // Low risk translations
  "defects-problems": "Defects / problems",
  "low-warning-middle":
    "that do not require immediate action or clarification, as well as life cycle problems that should be considered in the",
  "future-maintenance": "future maintenance program",
  "low-warning-end":
    "as the end of the expected service life will be reached in the period under consideration.",

  //Days
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
};

