import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import SubAssetSwitcher from "../../../../component-bundle/assetSwitcher/SubAssetSwitcher";
import { getCategoryNavlinks } from "../../../api/keyFindingApi";
import { resetState } from "../keyFindingSlice";
import KeyFindingsContainer from "./KeyFindingsContainer";
import FilterOptions from "./FilterOptions";
import InternalPrompt from "../../../../../components/prompt/InternalPrompt";
import { setOpenAsset } from "../../asset-management/asset/OpenAssetSlice";
import {
  getWarningText,
  getTranslationVariantText,
} from "../../../../../utils/helper";
import NoAssets from "../../../../component-bundle/NoAssets";

// Constants for event names
const DEFAULT_ASSET_CHANGED = 'defaultAssetChanged';
const DEFAULT_SUBASSET_CHANGED = 'defaultSubAssetChanged';

const CategoriesSectionStyle = {
  maxHeight: `calc(100% - 50px)`,
  height: `calc(100% - 50px)`,
  overflow: "hidden",
};

function KeyFindingsDataWrapper(props) {
  const scope = useSelector((state) => state.container.scope);
  const openAsset = useSelector((state) => state.openAsset);
  const state = useSelector((state) => state.tddKeyFinding);
  const dispatch = useDispatch();
  const [categoriesTab, setCategoriesTab] = useState([]);
  const [navlinksData, setNavLinksData] = useState([]);
  const [blockSubAssetChange, setBlockSubAssetChange] = useState({
    block: false,
    newSubAsset: {},
  });
  const readOnlystate = useSelector((state) => state.readOnly);

  // Enhanced event handling for default changes
  useEffect(() => {
    const handleDefaultAssetChange = async (event) => {
      // Handle forced update from Asset Switcher
      if (event.detail?.force) {
        try {
          // Get the default from localStorage to determine what to do
          const storedDefaultAsset = localStorage.getItem('defaultAsset');
          if (storedDefaultAsset) {
            const defaultAsset = JSON.parse(storedDefaultAsset);
            
            // Check if the default asset belongs to the current project
            if (defaultAsset.projectIdent === scope.projectIdent) {
              // Get the default sub-asset if any
              const storedDefaultSubAsset = localStorage.getItem('defaultSubAsset');
              let subAssetData = null;
              
              if (storedDefaultSubAsset) {
                const defaultSubAsset = JSON.parse(storedDefaultSubAsset);
                // Make sure sub-asset belongs to the new default asset
                if (defaultSubAsset.assetIdent === defaultAsset.ident && 
                    defaultSubAsset.projectIdent === scope.projectIdent) {
                  subAssetData = defaultSubAsset;
                }
              }
              
              // Update the open asset with the new default
              dispatch(
                setOpenAsset({
                  assetName: defaultAsset.name,
                  ident: defaultAsset.ident,
                  subAssetName: subAssetData?.name || null,
                  subAssetIdent: subAssetData?.subAssetIdent || null,
                  isDefault: true,
                  isSubAssetDefault: !!subAssetData
                })
              );
              
              // If we have a sub-asset, fetch categories for it
              if (subAssetData?.subAssetIdent) {
                dispatch(
                  getCategoryNavlinks({
                    projectIdent: scope.projectIdent,
                    subAssetIdent: subAssetData.subAssetIdent,
                  })
                );
              }
            }
          }
        } catch (error) {
          console.error('Error handling default asset change:', error);
        }
      } 
      // Regular update when a default is set
      else if (event.detail?.ident && openAsset?.subAssetIdent) {
        // Check if categories need to be refreshed
        dispatch(
          getCategoryNavlinks({
            projectIdent: scope.projectIdent,
            subAssetIdent: openAsset.subAssetIdent,
          })
        );
      }
    };
    
    const handleDefaultSubAssetChange = (event) => {
      // Handle explicit sub-asset removal
      if (event.detail?.removed && openAsset?.subAssetIdent === event.detail.previousSubAssetIdent) {
        // Clear categories or show no selection
        setCategoriesTab([]);
        setNavLinksData([]);
      }
      // Handle when a new default sub-asset is set
      else if (event.detail?.subAssetIdent) {
        // Only refresh if this affects the current view
        if (openAsset?.isSubAssetDefault) {
          dispatch(
            getCategoryNavlinks({
              projectIdent: scope.projectIdent,
              subAssetIdent: event.detail.subAssetIdent,
            })
          );
        }
      }
    };

    window.addEventListener(DEFAULT_ASSET_CHANGED, handleDefaultAssetChange);
    window.addEventListener(DEFAULT_SUBASSET_CHANGED, handleDefaultSubAssetChange);
    
    return () => {
      window.removeEventListener(DEFAULT_ASSET_CHANGED, handleDefaultAssetChange);
      window.removeEventListener(DEFAULT_SUBASSET_CHANGED, handleDefaultSubAssetChange);
    };
  }, [dispatch, scope.projectIdent, openAsset]);

  // Fetch categories when openAsset changes
  useEffect(() => {
    if (!!openAsset?.subAssetIdent) {
      dispatch(
        getCategoryNavlinks({
          projectIdent: scope.projectIdent,
          subAssetIdent: openAsset.subAssetIdent,
        })
      );
    }
  }, [dispatch, scope.projectIdent, openAsset]);

  useEffect(() => {
    if (state.navLinks.type === "SUCCESS" && !!state.navLinks.data) {
      let navlinks = state.navLinks.data?.navLinks;
      if (!!navlinks && navlinks.length) {
        let temp = navlinks.map((m) => {
          return {
            tab: {
              tabName: m.title.find(
                (f) => f.language === scope.projectLanguageCode
              ),
              key: m.key,
              ident: m.ident,
              nodeType: m.nodeType,
              parent: m.parentId,
            },
            nodeType: m.nodeType,
            ident: m.ident,
            key: m.key,
            children: m.children,
          };
        });
        setNavLinksData(temp);
        setCategoriesTab(
          temp.map((m) => ({ ...m.tab, name: m.tab.tabName.title }))
        );
      }
      dispatch(resetState());
    }
  }, [dispatch, state, scope.projectLanguageCode]);

  const setBlockState = (val) => {
    setBlockSubAssetChange({
      block: true,
      newSubAsset: val,
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          marginBottom: 5,
          height: 50,
        }}
      >
        <div style={{ width: "20%", height: 50 }}>
          <SubAssetSwitcher
            dirtyState={state.updatedRows.length > 0}
            setBlockState={(val) => setBlockState(val)}
          />
        </div>
        {!!openAsset.ident && (
          <div style={{ width: "80%", height: 50 }}>
            <FilterOptions />
          </div>
        )}
      </div>
      {!!openAsset.ident ? (
        <div style={CategoriesSectionStyle}>
          <KeyFindingsContainer tabs={categoriesTab} navLinks={navlinksData} />
        </div>
      ) : (
        <NoAssets />
      )}
      {!readOnlystate.isReadOnly && (
        <InternalPrompt
          when={blockSubAssetChange.block}
          stayText="_back"
          saveText="Save And Leave"
          leaveText="_continue"
          onLeave={() => {
            dispatch(setOpenAsset(blockSubAssetChange.newSubAsset));
            setBlockSubAssetChange({
              block: false,
              newSubAsset: {},
            });
          }}
          onStay={() =>
            setBlockSubAssetChange({
              block: false,
              newSubAsset: {},
            })
          }
          warningHeader={getTranslationVariantText("_leave warning", "body1")}
          message={getWarningText(state.updatedRows)}
        />
      )}
    </>
  );
}

KeyFindingsDataWrapper.propTypes = {};

export default KeyFindingsDataWrapper;