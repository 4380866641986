import { createSlice } from "@reduxjs/toolkit";
import { encrypt } from "../../../utils/helper";
export const initialState = {
  hasErrors: false,
  assetName: null,
  subAssetName: null,
  subAssetList: [],
  ident: null,
  subAssetIdent: null,
  users: null,
  isDefault: false,          // Added field for default asset flag
  isSubAssetDefault: false,  // Added field for default sub-asset flag
};

// A slice for auth with our success or failure reducers
const openAssetSlice = createSlice({
  name: "OpenAsset",
  initialState,
  reducers: {
    setOpenAsset: (state, { payload }) => ({
      ...state,
      hasErrors: false,
      assetName: payload.assetName,
      subAssetName: payload.subAssetName,
      ident: payload.ident,
      subAssetList: payload.subAssetList,
      users: encrypt(payload.userDtos),
      subAssetIdent: payload.subAssetIdent,
      isDefault: payload.isDefault || false,           // Default to false if not provided
      isSubAssetDefault: payload.isSubAssetDefault || false  // Default to false if not provided
    }),
    resetOpenAssetState: () => ({ ...initialState }),
    
    // Add a utility action to update just the default flags
    updateDefaultFlags: (state, { payload }) => ({
      ...state,
      isDefault: payload.isDefault,
      isSubAssetDefault: payload.isSubAssetDefault
    }),
  },
});

// eslint-disable-next-line max-len
export const { setOpenAsset, resetOpenAssetState, updateDefaultFlags } = openAssetSlice.actions;

// A selector
export const assetSelector = (state) => state.openAsset;

// The reducer
export default openAssetSlice.reducer;